import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle<{ $shadow: { high: string, low: string }, $dark: boolean }>`


[data-theme="light"] {
    --main-color: #ddd;
    --menu-btn-color: #777;
}
.theme-changer,.clipboard{
    height:32px;
    width:32px;
    padding: 8px;
    position: absolute;
    right: 6px;
    top: 6px;
    z-index:2;
}
.theme-changer svg{
  pointer-events: none;
}
.clipboard{
    left: 6px;
    right: initial;
}
.game-box,
.game-box-v2 {
  /* background: var(--main-color); */
  border-radius: 6px;
  /* box-shadow: -2px -2px 2px 0px ${props => props.$shadow.high}, 2px 2px 2px 1px ${props => props.$shadow.low}; */
  box-shadow: ${props => !props.$dark ? '3px 2px 8px -2px #00000066, -6px -6px 8px -2px #ffffffb3' : '2px 2px 2px -1px #0e0e0e, -2px -2px 2px 0px #1f1f1f'};
  
  color: var(--menu-btn-color);
  border: none;
  margin: 10px;
}

.game-box-v2 {
  /* background: var(--secondary-color); */
  box-shadow: ${props => !props.$dark ? '3px 2px 8px -2px #00000066, -6px -6px 8px -2px #ffffffb3,  inset 2px 2px 2px 1px #ffffffb3' : '2px 2px 2px -1px #0e0e0e, -2px -2px 2px 0px #0e0e0e'};

}`;