import React from 'react'
import styled from 'styled-components'
import { useTheme } from '../providers/Theme'
const StyledProgressBar = styled.div<{ $grd1: string, $grd2: string, $percent: number, $dark: boolean }>`
    .wrapper{
        position: relative;
        .bar{
            margin: 0 0px;
            border-radius: 25px;
            height:18px;
            width:auto;
            background-color: var(--main-color);
            // box-shadow: -2px -2px 2px 0px #1f1f1f, 2px 2px 2px 1px #0e0e0e;
            overflow: hidden;
            border: 1px solid var(--main-color);
            .progress{
                z-index: 1;
                // margin: 1px 0px;
                background: ${p => `linear-gradient(90deg,${p.$grd1},${p.$grd2})`};
                width: ${p => p.$percent}%;
                height: 100%;
                // border-radius: 20px;
                transition: width 1s linear;

            }
        }
        .handle-wrapper{
            width: calc(100% - 24px);
            left: 12px;
            // margin: 0px 9px;
            position: relative;
            // background: red;
            // outline: red 1px solid;
            height:20px;
            .handle{
                position: absolute;
                height: 12px;
                width: 12px;
                background: var(--main-color);
                transform: translate(-6px, 0);
                border-radius: 0 0 6px 6px;
                left: ${p => p.$percent}%;
                z-index: 0;
                box-shadow: ${props => props.$dark ? '2px 2px 2px 1px var(--shadow-dark)' : '3px 2px 8px -2px #0006'};
                transition: left 1s linear;
            }
        }
    }
`
function ProgressBar({ percent }: { percent: number }) {
    const { theme } = useTheme();
    return (
        <StyledProgressBar $grd1='#EDCE96' $grd2='#A48148' $percent={percent} $dark={theme === 'dark'}>
            <div className="wrapper">
                <div className="bar game-box">
                    <div className="progress">
                    </div>
                </div>
                <div className="handle-wrapper">
                    <div className="handle">
                    </div>
                    {/* <div className="inside"></div> */}
                </div>
            </div>
        </StyledProgressBar>
    )
}

export default ProgressBar