import styled from 'styled-components';

const StyledGameUI = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;

    #info{
        height: 25%;
        max-height: 25%;
        overflow: hidden;
        padding: 0 15%;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8%;

        span {
            color: var(--text-color-1);
            font-weight:bold;
            align-self: flex-start;
        }

        .balance {
            background: -webkit-linear-gradient(0deg, #EDCE96, #A48148);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 60px;
            // line-height: 50px;
            // margin-bottom: 15px;
        }
    }

    section#tasks{
        height: 75%;
        max-height: 75%;
        overflow: hidden;
        padding: 0 var(--h-padding);
        position: relative;
        .tabs{
            display: flex;
            gap:1px;
            justify-content: center;
            margin-bottom: 10px;
            border: 2px solid var(--main-color);
            background: var(--secondary-color);
            button{
                padding: 12px 12px;
                text-align: center;
                width: 100%;
                // padding: 7px 0px 7px 0;
                font-weight: bold;
                border: none;
                border-radius: 5px;
            }
        }

        .task-list{
            position: relative;
            // padding: 10px 0;
            padding-bottom: 20px;
            margin-top: 10px;
            margin-right: -10px;
            gap: 0px;
            justify-content: flex-start;
            overflow-y: auto;
            height: 100%;
            padding-right: 5px;
            .task-group{
                background-color: var(--secondary-color);
                padding: 12px;
                // opacity: 0.5;
                gap: 10px;
                .info{
                    align-items: center;
                    img.coin{
                        height: 38px;
                    }
                    .details{
                        justify-content: space-between;
                        flex: 1;
                        padding: 0 10px;
                        .title{
                            text-align: left;
                            color: var(--menu-btn-color);
                            font-size: 14px;
                            font-wieght: bold;
                        }
                        .reward{
                            text-align: left;
                            color: #ccc;
                            font-size: 10px;

                            svg{
                                margin-right: 5px;
                            }
                        }
                    }
                    button{
                        padding: 7px;
                        height: max-content;
                        width: 80px;
                        font-weight: 600;
                    }
                   
                }
                .progress{
                    padding: 5px 2px;
                }
            }
        }
    }
`

export { StyledGameUI }