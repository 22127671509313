import React from 'react'
import { StyledGameUI } from './styled.page'
import { BsChevronRight, BsCircleFill, BsCup, BsHourglassSplit, BsRobot } from "react-icons/bs";
import { FaHandPointUp, FaBolt } from "react-icons/fa";
import ProgressBar from '../../components/ProgressBar';
import cup from '../../assets/images/bronze-64.png';
import { useAppSelector } from '../../hooks';
import { User } from '../../types/User';
import TrophyLevel from '../../types/TrophyLevel';
import RestAPIClass from '../../services/rest.service';
import { ReactComponent as ShieldIcon } from '../../assets/icons/shield.svg';
import toast from 'react-hot-toast';
import { leagueData } from '../../features/gameFunctions';
import { deepClone, toNumberFormat } from '../../features/common';
function Index() {

    const { full: { referral_code, total_inventory, username }, invite, league } = useAppSelector(state => state);
    const { jwt } = useAppSelector(state => state.security);
    let levels = deepClone(league.levels)
    levels = levels.sort((a, b) => a.count - b.count)
    // console.log({ levels });


    const handleCopy = () => {
        try {
            navigator.clipboard.writeText('https://t.me/zizotapbot?start=' + referral_code)
                .then(() => {
                    toast('your referral link copied to clipboard.', { className: 'game-box success' });
                });
        } catch (error) {
            console.log(error);
        }
        // Telegram.WebApp.onEvent('clipboardTextReceived',())
    }

    const handleInvite = () => {
        handleCopy();
        let rest = new RestAPIClass(jwt);
        rest.GetInviteLink().then(response => {
            if (response.status === "success")
                Telegram.WebApp.close()
        });
    }

    return (
        <StyledGameUI className='main-section'>
            <section id="info">
                <div className='referrals'>{invite.length} Referrals</div>
            </section>

            <section id='referral-list'>


                <div className="divider game-box" style={{ height: '10px' }}>
                </div>
                {/* Welcome {username} */}
                {/* <div className="referral-link flex flex-v">
                    <div className="flex flex-h">
                        <span className="title">My Invite Link</span>
                        <button className='game-box gold-text clickable' onClick={handleCopy}>Copy</button>
                    </div>
                    https://t.me/zizotapbot/zizotap?start={referral_code}
                </div> */}
                <div className="referral-data flex flex-v">

                    <div className="invite-group flex flex-h">
                        <span className='title'>My Referrals</span>
                        <button className='game-box gold-text clickable' onClick={handleInvite}>Invite Friend</button>
                    </div>
                    <div className="invite-list flex flex-v custom-scroll">
                        {
                            // [ { name: 'Ali', level: 'gold', coins: ' 94 266', progress: 30, rate: '2,000+' }, ]
                            invite.length === 0 ?
                                <div className="message">
                                    <div className="icon">
                                        {<ShieldIcon />}
                                    </div>
                                    <div className="message-text">You dont't have referrals</div>
                                </div>
                                : invite.map((i: User) => {
                                    const { leagueIndex: index } = leagueData(levels, i.total_inventory);
                                    // let index = levels.findIndex(j => j.count >= i.total_inventory);
                                    let cul = levels[index];
                                    console.log({ cul });

                                    let progress = isNaN(i.total_inventory / cul.max) ? 0 : (i.total_inventory / cul.max) * 100;
                                    console.log(progress);
                                    return {
                                        name: i.username,
                                        coins: i.total_inventory,
                                        level: cul.title,
                                        rate: Math.round((i.total_inventory * 0.01) / 100) * 100,
                                        progress,
                                        icon: cul.image
                                    }
                                })
                                    .map((i, k) =>
                                        <div key={k} className="referral-group game-box flex flex-v clickable" onClick={() => Telegram.WebApp.openTelegramLink(`https://t.me/${i.name}`)}>
                                            <div className="info flex flex-v">
                                                <div className="user-data flex flex-v">
                                                    <div className="top-part flex flex-h">
                                                        <span className='title'>{i.name}</span>
                                                        <div className="rate"> {i.rate} <BsChevronRight size={12} /> </div>
                                                    </div>
                                                    <div className='level flex flex-h'>
                                                        <img src={i.icon} height={30} /><span className='gold-text'>{i.level} | </span> <BsCircleFill size={16} /> <span className='gold-text'>{toNumberFormat(i.coins)}</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="progress">
                                                <ProgressBar percent={i.progress} />
                                            </div>
                                        </div>
                                    )
                        }
                    </div>
                </div>
            </section>
        </StyledGameUI>
    )
}

export default Index