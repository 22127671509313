import React, { useEffect, useState } from 'react'
import { StyledGameUI } from './styled.page'
import { BsChevronRight, BsCircleFill, BsHourglassSplit, BsRobot } from "react-icons/bs";
import { FaHandPointUp, FaBolt, FaCircle, FaSpinner } from "react-icons/fa";
import ProgressBar from '../../components/ProgressBar';
import coin from '../../assets/images/zizo-coin-64.png';
import Layout from '../../pages/Layout';
import { useTheme } from '../../providers/Theme';
import { useNavigate } from 'react-router-dom';
import { deepClone, toNumberFormat } from '../../features/common';
import { useAppDispatch, useAppSelector } from '../../hooks';
import ReftTask, { SubTask } from '../../types/RefTask';
import rest from '../../services/rest.service';
import { EarnPlan } from '../../types/EarnPlan';
import toast from 'react-hot-toast';
import { setActiveReftask, setTasks } from '../../features/refTaskSlice';
import { EarnPlanMapper } from '../../features/dataMapper';
import RestAPIClass from '../../services/rest.service';
import { errorHandler } from '../../features/gameFunctions';
function Index() {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { title, description, hint, reward, tasks } = useAppSelector(state => state.refTask.activeRefTask);
    const { jwt } = useAppSelector(state => state.security);
    const [loading, setLoading] = useState(false);

    const [clicked, setClicked] = useState<boolean[]>(Array.from(Array(tasks.length)).map(i => false));
    console.log({ clicked });

    useEffect(() => {
        const handler = () => {
            navigate('/');
            Telegram.WebApp.BackButton.hide();
            Telegram.WebApp.BackButton.offClick(handler);
        }
        Telegram.WebApp.BackButton.onClick(handler);
        Telegram.WebApp.BackButton.show();
    }, []);

    const handleValidation = (item: EarnPlan) => {
        console.log({ item });
        let rest = new RestAPIClass(jwt);
        setLoading(true);
        rest.CheckSocialTask(item.id)
            .then(response => {
                if (response.status === "error")
                    toast(response?.error ?? "Task not completed", { className: 'game-box error toast' });
                if (response.status === 'success') {
                    toast("Task is completed", { className: 'game-box success toast' });
                    rest.GetUserEarnPlans().then(response => {
                        dispatch(setTasks(EarnPlanMapper(response.data)));
                        let x = deepClone(tasks);
                        let task = x.find(i => i.id == item.id);
                        if (task)
                            task.status = true;
                        dispatch(setActiveReftask({ title, description, hint, reward, tasks: x }))
                    })
                }
            }).catch(error => {
                errorHandler(error, 'Earn plan validation error.')
            }).finally(()=>{
                setLoading(false);
            })
    }

    return (
        <Layout hasFooter={false}>
            <StyledGameUI className='main-section' $dark={theme === 'dark'}>
                <section id="message">
                    <div className="game-box-v2">
                        <h4 className='task-title gold-text'> {title} </h4>
                        <p className='task-description'>{description}</p>
                        <p className='task-help gold-text'>{hint}</p>
                    </div>
                </section>

                <section id='action' className='flex flex-v topline-divider' data-theme={theme}>
                    <div className="reward game-box" style={{ marginTop: '30px' }}>
                        <div className="container flex flex-h">
                            <img src={coin} alt="coin" className='coin' />
                            <div className="details flex flex-v">
                                <div className='title'>Reward</div>
                                <div className='reward-amount'>{toNumberFormat(reward)}</div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="start-btn game-box flex">
                        <div className='c1 flex'>
                            <div className="game-box c2 flex">
                                <button className="game-box gold-text c3">
                                    Start
                                </button>
                            </div>
                        </div>
                    </div> */}
                    <div className="group flex flex-v">
                        <div className="title">Your Task</div>
                        {tasks.map((i, k) => <div key={k} className="game-box clickable" onClick={() => {
                            setClicked(a => { a[k] = true; return [...a]; })
                            if (i?.link)
                                Telegram.WebApp.openLink(i.link)
                            else
                                toast('The task link is not set', { className: "game-box error toast" })
                        }}>
                            <div className="container flex flex-h">
                                <div className="task-title">
                                    <FaCircle fill='#c1a067' />
                                    {i.title}
                                </div>
                                {i.status ?
                                    <button className="gold-text disabled">Done</button> :
                                    <button disabled={!clicked[k]} className={`spinner-btn clickable gold-btn flex ${clicked[k] && !loading ? 'enabled' : 'disabled'}`} onClick={(e) => { handleValidation(i as EarnPlan); e.preventDefault(); e.stopPropagation(); }}>
                                        Check
                                        {loading && <FaSpinner />}
                                    </button>
                                }
                            </div>
                        </div>
                        )}
                    </div>
                </section>
            </StyledGameUI>
        </Layout>
    )
}

export default Index