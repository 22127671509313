import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import { useTheme } from '../providers/Theme'
import { UpdateRef } from '../types/Interface';
import ProgressBar from './ProgressBarV3';

const ProgressBarWithRef = forwardRef((props: { total: number, value: number }, ref: Ref<UpdateRef<number>>) => {
    // function ProgressBar({ percent, refValue }: { percent: number, refValue: Ref<number> }) {

    const { theme } = useTheme();
    const [percent, setPercent] = useState((props.value / props.total) * 100);

    useImperativeHandle(ref, () => ({
        update(newValue) {
            // console.log('progress bar');

            setPercent((newValue / props.total) * 100);
        }
    }));

    return <ProgressBar percent={percent} />

});

export default ProgressBarWithRef;