import styled from 'styled-components';

const StyledGameUI = styled.div<{ $dark: boolean }>`
    overflow: hidden;
    display: flex;
    flex-direction: column;

    #message{
        height: 25%;
        max-height: 25%;
        overflow: hidden;
        padding: 0 var(--h-padding);
        align-items: strech;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 8%;
        .game-box-v2{
            padding: 6px 10px;
            margin-bottom:20px;
            
            .task-title {
                color: #aaa;
                font-weight:bold;
                align-self: flex-start;
                margin: 4px 0;
            }
            
            .task-description, .task-help {
                // margin-bottom: 15px;
                font-size: 12px;
                font-weight: bold;
                margin: 3px 0;
            }
        }
    }

    section#action{
        height: 75%;
        max-height: 75%;
        overflow: hidden;
        padding: 0 var(--h-padding);
        // box-shadow: 0px -2px 0px 2px #1f1f1f;
        align-items: center;
        justify-content: space-between;
        // gap: 2%;

            .reward{
                width: 100%;
                gap: 8%;
                .container{
                    margin: 15px;
                    img{
                        // width: 25%;
                        height: 60px;
                    }
                    .details{
                        justify-content: space-around;
                        padding-left: 20px;
                        align-items: start;
                        .title{
                            font-size: 30px;
                            color: var(--text-color-2);
                        }
                        .title,.reward-amount{
                            color: var(--text-color-2);
                        }
                    }
                }
            }
        
            .start-btn{
                margin: 3%;
                height: 25vh;
                width: 25vh;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                .c1{
                    height: 62%;
                    width: 62%;
                    border-radius: 50%;
                    background-color: var(--secondary-color);
                    box-shadow: ${props => props.$dark ? 'inset 2px 2px 2px 0px #0008' : 'inset 2px 2px 4px -2px #6d6d6d88'};
                    align-items: center;
                    justify-content: center;
                    
                    .c2{
                        height:75%;
                        width:75%;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        padding: 4px;
                        .c3{
                            height:90%;
                            width:90%;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 25px;
                        }
                    }
                }
            }

            .group{
                width: 100%;
                height: 40%;
                overflow-y: auto;
                text-align: left;

                .title{
                    color: var(--menu-btn-color);
                    margin-bottom: 10px;
                }
                .container{
                    padding: 15px 10px;
                    // gap: 10px;
                    align-items: center;
                    justify-content: space-between;
                    .task-title{
                        color: var(--text-color-2);

                        svg{
                            margin-right: 10px;
                            vertical-align: bottom;
                        }
                    }
                    button{
                        padding: 8px 12px;
                        border-radius: 5px;
                        width: 40%;
                        font-weight: bold;
                    }
                    .spinner-btn{
                        justify-content: center;
                        align-items: center;
                        gap: 10px;

                        svg{
                          animation: spin 1.5s linear infinite;
                        }
                    }
                    .spinner-btn.disabled{
                        opacity: 0.6;
                        pointer-events: none;
                    }
                }
            }
        }
}
`

export { StyledGameUI }