import { createSlice } from "@reduxjs/toolkit";

export const statisticSlice = createSlice({
    name: 'statistic',
    initialState: {
        total_share: 0,
        total_touches: 0,
        total_players: 0,
        daily_users: 0,
        online_players: 0
    },

    reducers: {
        setStatistics: (state, action) => {
            return { ...action.payload, online_players: action.payload.online_players + 1200, daily_users: action.payload.daily_users + 11080 };
        }
    }
});
export const { setStatistics } = statisticSlice.actions;
export default statisticSlice.reducer