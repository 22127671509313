import { StyledGameUI } from './styled.page'
import { useAppDispatch, useAppSelector } from '../../hooks';
import millify from 'millify';
import { setActivePage } from '../../features/pageSlice';
function Index() {
    const { statistics } = useAppSelector(state => state)
    const dispatch = useAppDispatch()
    const titles: { [k: string]: string } = {
        total_share: "Total Share balance",
        total_touches: "total touches",
        total_players: "total players",
        daily_users: "daily users",
        online_players: "online players",
    }
    return (
        <StyledGameUI className='main-section'>
            <section id="info">
                <span> {titles.total_share} </span>
                <strong className='balance'>{millify(statistics.total_share, { precision: 2, space: true })}</strong>
            </section>

            <section id='stats' className='flex flex-v'>
                <div className="divider game-box" style={{ height: '10px' }} />

                <div className="stats-data game-box-v2 flex flex-v custom-scroll">
                    {
                        Object.entries(statistics).slice(1).map(([k, i], index) =>
                            <div key={index} className="stat-group flex flex-h">
                                <span className='title'>{titles[k]}</span>
                                <span className='amount gold-text'> {millify(i, { precision: 2, space: true })}</span>
                            </div>
                        )
                    }
                </div>
                <div className="divider game-box" style={{ height: '10px' }} />
                <button className='game-box gold-text darker clickable' onClick={() => {
                    dispatch(setActivePage('top_users'))
                }} >Top Players</button>
            </section>
        </StyledGameUI>
    )
}

export default Index