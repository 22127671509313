import React, { useState } from 'react'
import { StyledGameUI } from './styled.page'
import { BsCheck, BsChevronRight, BsCircleFill, BsHourglassSplit, BsRobot } from "react-icons/bs";
import { FaHandPointUp, FaBolt } from "react-icons/fa";
import ProgressBar from '../../components/ProgressBar';
import coin from '../../assets/images/zizo-coin-64.png';
import { useTheme } from '../../providers/Theme';
import { useNavigate } from 'react-router-dom';
import { clamp, deepClone, intersectByKeys, toNumberFormat } from '../../features/common';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setAll } from '../../features/fullSlice';
import { setActiveReftask, setLeagues, setTasks, updateReftask } from '../../features/refTaskSlice';
import CountUp from 'react-countup';
import rest from '../../services/rest.service';
import { LeagueRewardPlan, RewardPlan } from '../../types/RewardPlan';
import { UserClaim } from '../../types/UserClaim';
import { setClaimLeagues } from '../../features/claimSlice';
import { EarnPlanMapper } from '../../features/dataMapper';
import toast from 'react-hot-toast';
import RestAPIClass from '../../services/rest.service';
import { errorHandler } from '../../features/gameFunctions';
enum Tasks {
    special,
    league,
    refs
}

function Index() {
    const [activeTask, setActiveTask] = useState<Tasks>(Tasks.special);
    const { theme } = useTheme();
    const navigate = useNavigate();
    // const navigate= (url:string)=>{}
    const dispatch = useAppDispatch()
    const { total_inventory, inventory, invited_count } = useAppSelector(state => state.full);
    const { tasks, invites, leagues } = useAppSelector(state => state.refTask);
    const { invites: claimInvites, leagues: claimLeagues } = useAppSelector(state => state.claims);
    const { jwt } = useAppSelector(state => state.security);
    console.log({ claimLeagues })
    const claimReward = (id: number, reward: number, type: 'invite' | 'league' | 'social_media_task') => {
        if (id < 1) {
            toast('this claim is invalid', { className: 'game-box error' });
            return;

        }
        let rest = new RestAPIClass(jwt)
        rest.ClaimReward(id).then(response => {
            if (response.status === 'success') {
                dispatch(setAll({ inventory: inventory + reward }));
                // dispatch(setClaimLeagues(claimLeagues.map((i: any) => ({ ...i, is_paid: i.is_paid || i.claimId === id }))));
                if (type === 'league') {

                    let x = deepClone(leagues);
                    let l = x.find(i => i.userClaim.length > 0 && i.userClaim[0].id == id)

                    if (l && !l.claimStatus) {
                        l.claimStatus = true;
                        dispatch(updateReftask({ leagues: x }));
                    }
                }

                if (type === 'invite') {

                    let x = deepClone(invites);
                    let l = x.find(i => i.userClaim.length > 0 && i.userClaim[0].id == id)

                    if (l && !l.claimStatus) {
                        l.claimStatus = true;
                        dispatch(updateReftask({ invites: x }));
                    }
                }
                if (type === 'social_media_task') {
                    rest.GetUserEarnPlans().then(response => {
                        dispatch(setTasks(EarnPlanMapper(response.data)))
                    })
                }
            } else {
                errorHandler(response.error, "reward could not claimed.");
            }
        }).catch(error => {
            errorHandler(error, "reward claim error.");
        }).finally(() => {

            let x = deepClone(leagues);
            console.log({ leagues, x });
            let l = x.find(i => i.userClaim.length > 0 && i.userClaim[0].id == id)
            console.log({ l });
            if (l && !l.claimStatus) {
                l.claimStatus = true;
                console.log({ leagues, x, l });

                updateReftask({ leagues: { ...x } });
            }
        })
    }

    return (
        <StyledGameUI className='main-section'>
            <section id="info">
                <span> Your Share balance </span>
                <strong className='balance'>
                    <CountUp start={clamp(0, inventory, inventory - 100)} end={inventory} duration={3} />
                </strong>
                {/* <strong className='balance'>${toNumberFormat(inventory)}</strong> */}
            </section>

            <section id='tasks' data-theme={theme} className='topline-divider flex flex-v scroll-fade'>
                <div className="tabs game-box" style={{ marginTop: '30px' }}>
                    {['Special', 'Leagues', 'Ref Tasks'].map((i, k) => {
                        return <button key={k} className={'clickable ' + (activeTask == k ? 'gold-btn' : 'gold-text')} onClick={() => setActiveTask(k)}>{i}</button>
                    })}

                </div>

                <div className="task-list flex flex-v custom-scroll">
                    {[

                        // [
                        //     {
                        //         title: 'Connect solana wallet', desctiption: 'The heartbeat of breaking news.', reward: 100000, tasks: [
                        //             { type: 'join_telegram_chat', title: 'join the telegram chat', url: 't.me/xxxxx', checkLink: '/api/xxxxx', staus: true },
                        //         ]
                        //     },
                        //     {
                        //         title: 'Join our socials', desctiption: 'The heartbeat of breaking news.', reward: 200000, tasks: [
                        //             { type: 'join_telegram_chat', title: 'join the telegram chat', url: 't.me/xxxxx', checkLink: '/api/xxxxx', staus: true },
                        //             { type: 'follow_twitter_page', title: 'follow X handle', url: 'x.com/xxxxx', checkLink: '/api/xxxxx', staus: false },
                        //             { type: 'visit_website', title: 'visit website', url: 'https://zizo.club', checkLink: '/api/xxxxx', staus: false },
                        //         ]
                        //     },
                        //     {
                        //         title: 'Subscribe to crypto news chat', desctiption: 'The heartbeat of breaking news.', reward: 300000, tasks: [
                        //             { type: 'join_telegram_chat', title: "Don't miss out on crypto insights - join now!", url: 't.me/xxxxx', checkLink: '/api/xxxxx', staus: true },
                        //             { type: 'follow_twitter_page', title: 'follow X handle', url: 'x.com/xxxxx', checkLink: '/api/xxxxx', staus: false },
                        //         ]
                        //     },
                        //     {
                        //         title: 'Never miss key insight', desctiption: 'The heartbeat of breaking news.', reward: 300000, tasks: [
                        //             { type: 'join_telegram_chat', title: 'join the telegram chat', url: 't.me/xxxxx', checkLink: '/api/xxxxx', staus: true },
                        //             { type: 'follow_twitter_page', title: 'follow X handle', url: 'x.com/xxxxx', checkLink: '/api/xxxxx', staus: false },
                        //         ]
                        //     },
                        //     {
                        //         title: 'Subscribe crypto news', desctiption: 'The heartbeat of breaking news.', reward: 300000, tasks: [
                        //             { type: 'join_telegram_chat', title: 'join the telegram chat', url: 't.me/xxxxx', checkLink: '/api/xxxxx', staus: true },
                        //             { type: 'follow_twitter_page', title: 'follow X handle', url: 'x.com/xxxxx', checkLink: '/api/xxxxx', staus: false },
                        //         ]
                        //     },
                        //     {
                        //         title: 'Never miss key insight', desctiption: 'The heartbeat of breaking news.', reward: 300000, tasks: [
                        //             { type: 'join_telegram_chat', title: 'join the telegram chat', url: 't.me/xxxxx', checkLink: '/api/xxxxx', staus: true },
                        //             { type: 'follow_twitter_page', title: 'follow X handle', url: 'x.com/xxxxx', checkLink: '/api/xxxxx', staus: false },
                        //         ]
                        //     },
                        // ]
                        tasks.map((i, k) => {
                            const done = i.tasks?.every(j => j.status) ?? false;

                            return <div key={k} className="task-group game-box flex flex-v clickable" onClick={() => {
                                dispatch(setActiveReftask(i))
                                navigate('task-detail');
                            }}>
                                <div className="info flex flex-h">
                                    <img src={coin} alt="coin" className='coin' />
                                    <div className="details flex flex-v">
                                        <span className='title'>{i.title}</span>
                                        <span className='reward gold-text'>
                                            <BsCircleFill size={14} style={{ verticalAlign: 'baseline', color: '#C1A067' }} /> {toNumberFormat(i.reward)}</span>
                                    </div>
                                    {done ? (i?.claim?.is_paid ? <BsCheck size={25} /> : <button className='game-box gold-text clickable' onClick={(e) => { claimReward(i?.claim?.id ?? 0, i.reward, 'social_media_task'); e.stopPropagation(); }}>Claim</button>) : <BsChevronRight size={20} />}
                                </div>
                            </div>
                        }),
                        leagues.map(i => ({ ...i, from: i.count })).filter(i => !i.claimStatus).map((i, k) => {
                            let percent = (total_inventory / i.from) * 100;
                            // let intersect = intersectByKeys<any, LeagueRewardPlan>(claimLeagues, [i], i => i.id, i => i.id)
                            // let claimId = -1;
                            // if (intersect.length > 0)
                            //     claimId = intersect[0].claimId;

                            return <div key={k} className="task-group game-box flex flex-v">
                                <div className="info flex flex-h">
                                    <img src={coin} alt="coin" className='coin' />
                                    <div className="details flex flex-v">
                                        <span className='title'>{i.title}</span>
                                        <span className='reward gold-text'>
                                            <BsCircleFill size={14} style={{ verticalAlign: 'baseline', color: '#C1A067' }} /> {toNumberFormat(i.reward)}</span>
                                    </div>
                                    <button
                                        className={`clickable game-box gold-text ${i.status ? '' : 'disabled'}`}
                                        onClick={() => claimReward(i?.userClaim[0]?.id, i.reward, 'league')}>Claim</button>
                                </div>
                                <div className="progress">
                                    <ProgressBar percent={percent} />
                                </div>
                            </div>
                        }),

                        invites.filter(i => !i.claimStatus).map((i, k) => {
                            const percent = (invited_count / i.count) * 100;
                            return <div key={k} className="task-group game-box flex flex-v">
                                <div className="info flex flex-h">
                                    <img src={coin} alt="coin" className='coin' />
                                    <div className="details flex flex-v">
                                        <span className='title'>{i.title}</span>
                                        <span className='reward gold-text'>
                                            <BsCircleFill size={14} style={{ verticalAlign: 'baseline', color: '#C1A067' }} /> {toNumberFormat(i.reward)}</span>
                                    </div>
                                    <button
                                        className={`clickable game-box gold-text ${i.status ? '' : 'disabled'}`}

                                        onClick={() => claimReward(i.userClaim[0]?.id, i.reward, 'invite')}>Claim</button>
                                </div>
                                <div className="progress">
                                    <ProgressBar percent={percent} />
                                </div>
                            </div>
                        }
                        ),


                    ][activeTask]}
                </div>
            </section>
        </StyledGameUI>
    )
}

export default Index