import { useEffect, useState } from 'react';
import './App.css';
import Game from './pages/Game';
import { ThemeProvider, useTheme } from './providers/Theme';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Leagues from './pages/Leagues';
import { TaskDetailPage } from './sections/TaskDetailPage';
import rest from './services/rest.service';
import { setAll } from './features/fullSlice';
import { useAppDispatch, useAppSelector } from './hooks';
import { io } from "socket.io-client";
import Loading from './components/Loading';
import { setInvites, setLeagues, setTasks } from './features/refTaskSlice';
import { setLeagues as setLeagueData } from './features/leagueSlice';
import { setSpeed, setStorage, setTap } from './features/boosterSlice';
import { setAll as setInvitedUsers } from './features/inviteSlice';
import { errorHandler, parseLeaguePlan } from './features/gameFunctions';
import { LeagueRewardPlan } from './types/RewardPlan';
import { setClaimInvites, setClaimLeagues } from './features/claimSlice';
import { setStatistics } from './features/statisticSlice';
import { EarnPlanMapper } from './features/dataMapper';
import QrRedirectPage from './components/QrRedirectPage';
import RestAPIClass from './services/rest.service';
import { setJWT } from './features/securitySlice';
import Layout from './pages/Layout';
import { UserDataMetaValueJson } from './types/UserData';
import usePreventTelegramCollapse from './hooks/PreventTelegramCollapse';
import toast from 'react-hot-toast';
import useNetworkStatus from './hooks/UseNetworkStatus';
import { BsTools } from 'react-icons/bs';
import Maintenance from './components/Maintenance';
import pageSlice, { setActivePage } from './features/pageSlice';
import { PopupProvider } from './providers/Popup';

const pages = ['refs', 'tasks', 'home', 'boost', 'stats', 'apps', 'top_users'];
function App() {

  usePreventTelegramCollapse();
  const isOnline = useNetworkStatus();
  console.log({ isOnline });

  const { setTheme } = useTheme();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(state => state.full);
  // const [loading, setLoading] = useState(true);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [socket, setSocket] = useState<any>();
  const [authorized, setAuthorized] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const { jwt } = useAppSelector(state => state.security)
  const [socketAddress, setSocketAddress] = useState(process.env.REACT_APP_BASE_API_URL)
  // const [jwt, setJwt] = useState(localStorage.getItem('jwt') ?? '');

  const switchSocket = () => {
    if (socketAddress == process.env.REACT_APP_BASE_API_URL)
      setSocketAddress(process.env.REACT_APP_FALLBACK_API_URL);
    else if (socketAddress == process.env.REACT_APP_FALLBACK_API_URL)
      setSocketAddress(process.env.REACT_APP_BASE_API_URL);
  }
  useEffect(() => {
    function logTouchEvent(event: globalThis.TouchEvent) {
      // Get the target element of the touch event
      const targetElement = event.target as HTMLElement;
      // console.log('Parent element:', targetElement);
      // toast('Touched element:' + JSON.stringify(targetElement));

      // Log the parent elements up to the topmost parent
      let parentElement = targetElement.parentElement;
      while (parentElement) {
        // console.log('Parent element:', parentElement);
        // toast('Touched element:' + JSON.stringify(parentElement));
        parentElement = parentElement.parentElement;
      }
    };

    // Add event listeners for touch events
    document.addEventListener('touchstart', logTouchEvent, { passive: false });
    document.addEventListener('touchmove', logTouchEvent, { passive: false });
    document.addEventListener('touchend', logTouchEvent, { passive: false });

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('touchstart', logTouchEvent);
      document.removeEventListener('touchmove', logTouchEvent);
      document.removeEventListener('touchend', logTouchEvent);
    };
  }, []);

  useEffect(() => {
    if (jwt == '') return;
    console.log('socket init', socketAddress);

    let socket = io(socketAddress ?? "http://localhost:4000", {
      extraHeaders: {
        authorization: `Bearer ${jwt}`
      }
    });


    socket.on('connect', () => {
      console.log('socket connected 2');
    });

    socket.io.on('reconnect_attempt', (error) => {
      if (error > 1) {
        switchSocket();
        console.log('socket connect error', error, socketAddress);
        socket.io.off('reconnect_attempt');
        socket.disconnect();
      }
    })


    setSocket(socket);
  }, [jwt, socketAddress])


  useEffect(() => {
    try {
      Telegram.WebApp.ready();


      // Telegram.WebApp.onEvent("viewportChanged", function ({ isStateStable }) {
      //   var r = document.querySelector(':root');
      //   if(r){
      //     var rs = getComputedStyle(r);
      //     rs.getPropertyValue('--tg-')
      //   }
      // })

      // if (!(Telegram.WebApp.platform in ['ios', 'android'])) {
      //   setIsDesktop(true);
      // }
      // console.log({ tvh: Telegram.WebApp.viewportHeight });
      // setTheme(Telegram.WebApp.colorScheme);
      // setTheme(localStorage.getItem('theme') ?? Telegram.WebApp.colorScheme);
      if (jwt == '') {
        const params = new URLSearchParams(window.location.search);
        const pjwt = params.get('token') ?? false;
        const page = params.get('page') ?? false;
        if (pjwt && pjwt !== '') {
          // localStorage.setItem('jwt', pjwt);
          // setJwt(pjwt);
          setAuthorized(true);
          dispatch(setJWT(pjwt));
          if (page && pages.includes(page))
            dispatch(setActivePage(page))
          // return () => localStorage.removeItem('jwt');
        } else {
          new RestAPIClass('').Register().then(response => {
            if (response.status === "success") {
              console.log('registr', { response });
              dispatch(setJWT(response.data.jwt));
              setAuthorized(true);
            } else {
              errorHandler('registration faild');
            }
          }).catch(e => {
            setAuthorized(false);
          })
        }
      } else {
        setAuthorized(true);
      }

      if (authorized && jwt != '') {
        let rest = new RestAPIClass(jwt);
        try {
          rest.GetInitialData().then(response => {
            if (response.status === "success") {
              console.log(response.data);
              const { user, counter, robotSecondRemain, robotProfit } = response.data;
              let user_meta: UserDataMetaValueJson = JSON.parse(user.UserData?.find(i => i.userId === user.id)?.meta_value ?? '{}');
              dispatch(setAll({
                loading: false,
                user_id: user.chat_id,
                username: user.username,
                inventory: user.inventory - robotProfit,
                prev_inventory: user.inventory - robotProfit,
                total_inventory: user.total_inventory,
                energy: counter,
                storage: user.TotalEnergyLevel.count,
                per_tap: user.CoinLevel.count_per_snap,
                speed: user.SkipEnergyLevel.count + 1,
                referral_code: user.referral_code,
                coinLevel: user.coinLevelId,
                speedLevel: user.skipEnergyLevelId,
                energyLevel: user.totalEnergyLevelId,
                tank: [user.boostFillTotalEnergyCount, 3],
                guru: [user.boostIncreaseCountPerSnapCount, 3],
                robotSecondsRemain: robotSecondRemain,
                robotProfit,
                invited_count: user.invited_count,
                daily_counter: user_meta?.plans?.plan_7?.counter ?? 0
              }))
              // dispatch(setUserData({
              //   user_id: response.data.chat_id,
              //   storage: response.data.TotalEnergyLevel.count,
              //   inventory: response.data.inventory,
              //   total_inventory: response.data.total_inventory,
              //   referral_code: response.data.referral_code,
              //   per_tap: response.data.CoinLevel.count_per_snap
              // }))


              rest.GetGameStatistics().then(response => {
                if (response.status === 'success') {
                  dispatch(setStatistics({
                    total_share: response.data?.total_share ?? 0,
                    total_touches: response.data?.total_touches ?? 0,
                    total_players: response.data?.total_users ?? 0,
                    daily_users: response.data?.daily_users ?? 0,
                    online_players: response.data?.online_users ?? 0,
                  }))
                }
              })


              // rest.GetEarnPlans().then(response => {
              //   dispatch(setTasks(response.data));
              // });


              rest.GetUserEarnPlans().then(response => {
                if (response.status === 'success') {
                  dispatch(setTasks(EarnPlanMapper(response.data)));
                }
              });

              // rest.GetRewardPlans().then(response => {
              //   dispatch(setInvites(response.data.filter(i => i.type === 'invite')));
              //   dispatch(setLeagues(response.data.filter(i => i.type === 'league')));
              //   dispatch(setLeagueData(response.data.filter(i => i.type === 'league').map(i => parseLeaguePlan(i as LeagueRewardPlan))));
              // });

              rest.GetUserRewardPlans().then(response => {
                if (response.status === 'success') {
                  dispatch(setInvites(response.data.filter(i => i.rewardPlan.type === 'invite').sort((a, b) => a.rewardPlan.id - b.rewardPlan.id)));
                  dispatch(setLeagues(response.data.filter(i => i.rewardPlan.type === 'league').sort((a, b) => a.rewardPlan.id - b.rewardPlan.id)));
                  dispatch(setLeagueData(response.data.filter(i => i.rewardPlan.type === 'league').sort((a, b) => a.rewardPlan.id - b.rewardPlan.id).map(i => parseLeaguePlan(i as any))));
                } else {
                  errorHandler(response.error, 'could not load reward plans');
                }
              });
              rest.GetUserClaims().then(response => {
                // dispatch(setInvites(response.data.filter(i => i.RewardPlan && i.RewardPlan.type === 'invite').map(i => parseInvitePlan(i.RewardPlan as InviteRewardPlan))));
                // dispatch(setInvites(response.data.filter(i => i.RewardPlan && i.RewardPlan.type === 'league').map(i => parseLeaguePlan(i.RewardPlan as LeagueRewardPlan))));
                if (response.status === 'success') {

                  dispatch(setClaimInvites(response.data.filter(i => i.RewardPlan != null && i.RewardPlan.type === 'invite').map(i => ({ ...i.RewardPlan, claimId: i.id, is_paid: i.is_paid }))));
                  dispatch(setClaimLeagues(response.data.filter(i => i.RewardPlan != null && i.RewardPlan.type === 'league').map(i => ({ ...i.RewardPlan, claimId: i.id, is_paid: i.is_paid }))));
                } else {
                  errorHandler(response.error, 'could not load user claims');
                }
              }).catch(e => {
                errorHandler(e.error, 'could not load user claims')
              })

              rest.GetTappingLevels().then(response => {
                if (response.status === 'success')
                  dispatch(setTap(response.data));
                else {
                  errorHandler(response.error, 'could not load tapping level data');
                }
              });

              rest.GetStorageLevels().then(response => {
                if (response.status === 'success')
                  dispatch(setStorage(response.data));
                else {
                  errorHandler(response.error, 'could not load storage level data');
                }
              });

              rest.GetSpeedLevels().then(response => {
                if (response.status === 'success')
                  dispatch(setSpeed(response.data));
                else {
                  errorHandler(response.error, 'could not load recharging level data');
                }
              });

              rest.GetReferrals().then(response => {
                if (response.status === 'success')
                  dispatch(setInvitedUsers(response.data));
                else {
                  errorHandler(response.error, 'could not load user referrals data');
                }
              });
              dispatch(setAll({ loading: false }));
            } else {
              // toast('coul not connect to server', { className: 'game-box error' });
              console.log(response);
              setMaintenanceMode(true);
            }
          }).catch(error => {
            console.log(error);
            // toast(error.toString(), { className: 'game-box error' });
            setMaintenanceMode(true);
          });
        }
        catch (e: any) {
          errorHandler(e);
        }
      }
    } catch (error: any) {
      errorHandler(error, 'Telegram library could not be loaded')
    }
  }, [authorized, jwt]);

  // console.log({ authorized, jwt });

  return (
    <div className="App">
      <ThemeProvider>
        <PopupProvider>

          {
            !isOnline ?
              <Layout>
                <div className='flex flex-v' style={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                  <h3 className='gold-text' style={{ padding: 20 }}> Network Error. check your internet connection</h3>
                  <p className='game-box gold-text' style={{ padding: 20 }}> Zizo Tap Team</p>
                </div>
              </Layout>
              :
              !authorized ?
                <Layout>
                  <div className='flex flex-v' style={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                    <h3 className='gold-text' style={{ padding: 20 }}> login required - unauthorized user</h3>
                    <a href='https://t.me/zizotapbot' className='game-box gold-text' style={{ padding: 20 }}> Zizo Tap</a>
                  </div>
                </Layout>
                :
                maintenanceMode ? <Maintenance /> :
                  loading ? <Loading /> :
                    isDesktop ? <QrRedirectPage /> :
                      <BrowserRouter>
                        <Routes>
                          <Route path='/' element={<Game socket={socket} />} />
                          <Route path='/leagues' element={<Leagues />} />
                          <Route path='/task-detail' element={<TaskDetailPage />} />
                        </Routes>
                      </BrowserRouter>
          }
        </PopupProvider>
      </ThemeProvider>
      <div className="spare"></div>
    </div >
  );
}

export default App;
