import React, { useEffect, useState } from 'react'
import { StyledGameUI } from './styled.page'
import { BsChevronRight, BsFingerprint, BsHourglassSplit, BsRobot } from "react-icons/bs";
import { FaHandPointUp, FaBolt, FaHourglass, FaBatteryFull, FaHandPaper } from "react-icons/fa";
import Drawer, { DrawerInput } from '../../components/Drawer';
import { BooleanProvider, useBoolean } from '../../hooks/DrawerHandler';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useTheme } from '../../providers/Theme';
import { max, toNumberFormat } from '../../features/common';
import { useAppDispatch, useAppSelector } from '../../hooks';
import rest from '../../services/rest.service';
import { setTap } from '../../features/boosterSlice';
import { setAll } from '../../features/fullSlice';
import CountUp from 'react-countup';
import toast from 'react-hot-toast';
import { setActivePage } from '../../features/pageSlice';
import { ReactComponent as MultitapIcon } from '../../assets/icons/press.svg';
import { ReactComponent as FlashIcon } from '../../assets/icons/flash-fill.svg';
import { ReactComponent as HourGlassIcon } from '../../assets/icons/hourglass-start.svg';
import { ReactComponent as RobotIcon } from '../../assets/icons/gps.svg';
import RestAPIClass from '../../services/rest.service';
import { errorHandler } from '../../features/gameFunctions';

function Index() {

    const iconSize = 80;
    const { booleanValue, toggleBooleanValue } = useBoolean();
    const [input, setInput] = useState<DrawerInput>({ icon: <AiOutlineLoading3Quarters />, title: 'loading...', description: 'loading ...', cost: 0, nextLevel: 1, action: () => { }, disabled: false, disableText: '' });
    const { tappingLevels, speedLevels, storageLevels, tappingBot } = useAppSelector(state => state.boosters);
    const { jwt } = useAppSelector(state => state.security);
    const { guru, tank, prev_inventory, inventory, coinLevel, energyLevel, speedLevel, per_tap, storage, speed, robotSecondsRemain } = useAppSelector(state => state.full);
    let isActiveRobot = robotSecondsRemain > 0;
    const dispatch = useAppDispatch();
    const [prevInventory, setPrevInventory] = useState(prev_inventory);

    useEffect(() => {
        setPrevInventory(prev_inventory)
    }, [prev_inventory])

    useEffect(() => {
        return () => {
            dispatch(setAll({ prev_inventory: inventory }));
        }
    }, [inventory])


    const handleBoost = (type: string) => {
        try {


            let rest = new RestAPIClass(jwt);
            switch (type) {
                case 'multitap':
                    rest.UpgradeMultiTap()
                        .then(response => {
                            if (response.status == "success") {
                                setTimeout(() => {
                                    setPrevInventory(inventory - (tappingLevels.find(i => i.level == coinLevel + 1)?.cost ?? 0))
                                }, 1000);
                                dispatch(setAll({ per_tap: per_tap + 1, inventory: inventory - (tappingLevels.find(i => i.level == coinLevel + 1)?.cost ?? 0), coinLevel: coinLevel + 1 }))
                                toggleBooleanValue();
                                toast('Your tapping power is upgraded.', { className: 'game-box success' });
                            } else {
                                errorHandler('something went wrong. please try again.')
                            }
                        }
                        );
                    break;
                case 'storage':
                    rest.UpgradeStorage()
                        .then(response => {
                            console.log(response);

                            if (response.status === "success") {
                                setTimeout(() => {
                                    setPrevInventory(inventory - (storageLevels.find(i => i.level == energyLevel + 1)?.cost ?? 0))
                                }, 1000);
                                dispatch(setAll({ storage: storage + 500, energy: storage + 500, inventory: inventory - (storageLevels.find(i => i.level == energyLevel + 1)?.cost ?? 0), energyLevel: energyLevel + 1 }))
                                toggleBooleanValue();
                                toast('Your storage limit is upgraded.', { className: 'game-box success' });
                            } else {
                                errorHandler(response.error, "Something went wrong. please try again.")
                            }
                        }).catch(error => {
                            errorHandler(error)
                        });
                    break;
                case 'recharge':
                    rest.UpgradeRecharge()
                        .then(response => {
                            if (response.status === "success") {
                                setTimeout(() => {
                                    setPrevInventory(inventory - (speedLevels.find(i => i.level == speedLevel + 1)?.cost ?? 0));
                                }, 1000);
                                dispatch(setAll({ speed: speed + 1, inventory: inventory - (speedLevels.find(i => i.level === speedLevel)?.cost ?? 0), speedLevel: speedLevel + 1 }))
                                toggleBooleanValue();
                                toast('Your recharging speed is upgraded.', { className: 'game-box success' });
                            } else {
                                errorHandler("Something went wrong.")
                            }
                        });
                    break;
                case 'bot':
                    rest.ActivateBot().then(response => {
                        if (response.status === "success") {
                            setTimeout(() => {
                                setPrevInventory(inventory - tappingBot.cost)
                            }, 1000);
                            dispatch(setAll({ tappingBot: { is_active: true }, inventory: inventory - tappingBot.cost }))
                            toast('Your tapping bot is activated.', { className: 'game-box success' });
                        } else {
                            errorHandler(response.error, "Something went wrong.")
                        }
                    });

                    break;
                case 'tapping-guru':
                    rest.ActivateGuru().then(response => {
                        if (response.status === "success") {
                            dispatch(setAll({ guru_mode: true, guru: [guru[0] - 1, guru[1]] }));
                            dispatch(setActivePage('home'));
                            toast("Good", { className: 'game-box success' })
                        }
                    });
                    break;
                case 'full-tank':
                    rest.FillTank().then(response => {
                        if (response.status === "success") {
                            dispatch(setAll({ tank: [tank[0] - 1, tank[1]], energy: storage }));
                            // toggleBooleanValue();
                            dispatch(setActivePage('home'));
                            toast("Good", { className: 'game-box success' })
                        }
                    });
                    break;

                default:
                    break;
            }

        } catch (error: any) {
            errorHandler(error, 'boost handle error.')
        }
    }
    const handleDrawer = (booster: string) => {
        switch (booster) {
            case 'multitap':
                let TL = tappingLevels.find(i => i.level == coinLevel + 1) ?? false;
                let TML = max(tappingLevels, i => i.level);
                setInput({
                    // icon: <FaHandPointUp color='#444' size={iconSize} />,
                    // icon: <MultitapIcon color='#444' height={iconSize} width={iconSize} />,
                    icon: <MultitapIcon height={iconSize} width={iconSize} />,
                    action: () => handleBoost(booster),
                    cost: TL ? TL.cost : 0,
                    nextLevel: TL ? TL.level : coinLevel,
                    description: TL ? TL.description : 'Max Level',
                    title: 'MultiTap',
                    disabled: coinLevel == TML?.level,
                    disableText: 'You reached to maximum level of this booster',
                })
                break;
            case 'storage':
                let EL = storageLevels.find(i => i.level == energyLevel + 1) ?? false;
                let EML = max(storageLevels, i => i.level);
                setInput({
                    // icon: <FaBolt color='#444' size={iconSize} />,
                    // icon: <FlashIcon color='#444' height={iconSize} width={iconSize} />,
                    icon: <FlashIcon height={iconSize} width={iconSize} />,
                    action: () => handleBoost(booster),
                    cost: EL ? EL.cost : 0,
                    nextLevel: EL ? EL.level : energyLevel,
                    description: EL ? EL.description : 'Max Level',
                    title: 'Storage Limit',
                    disabled: energyLevel == EML?.level,
                    disableText: 'You reached to maximum level of this booster',
                });
                break;
            case 'recharge':
                let SL = speedLevels.find(i => i.level == speedLevel + 1) ?? false;
                let SML = max(speedLevels, i => i.level);
                setInput({
                    // icon: <BsHourglassSplit color='#444' size={iconSize} />,
                    // icon: <HourGlassIcon color='#444' height={iconSize} width={iconSize} />,
                    icon: <HourGlassIcon height={iconSize} width={iconSize} />,
                    action: () => handleBoost(booster),
                    cost: SL ? SL.cost : 0,
                    nextLevel: SL ? SL.level : speedLevel,
                    description: SL ? SL.description : 'Max Level',
                    title: 'Recharging Speed',
                    disabled: speedLevel == SML?.level,
                    disableText: 'You reached to maximum level of this booster',
                })
                break;
            case 'bot':

                setInput({
                    // icon: <BsRobot color='#444' size={iconSize} />,
                    // icon: <RobotIcon color='#444' height={iconSize} width={iconSize} />,
                    icon: <RobotIcon height={iconSize} width={iconSize} />,
                    action: () => handleBoost(booster),
                    cost: tappingBot.cost,
                    nextLevel: 12,
                    description: tappingBot.description,
                    title: 'Tapping Robot',
                    costAndLevel: () => <span> {toNumberFormat(tappingBot.cost)} </span>,
                    disabled: isActiveRobot,
                    disableText: 'Your tapping robot is already active',
                })
                break;
            case 'tapping-guru':
                setInput({
                    icon: <FaHandPaper color='#444' size={iconSize} />,
                    action: () => handleBoost(booster),
                    cost: 1,
                    nextLevel: 1,
                    description: 'during this boost in 20 seconds your tap is multiplied by 5 times',
                    title: 'Tapping Guru',
                    costAndLevel: () => <span> Free </span>,
                    disabled: guru[0] <= 0,
                    disableText: 'You used all of this booster. you have to wait to refill booster',
                })
                break;
            case 'full-tank':
                setInput({
                    icon: <FaBatteryFull color='#444' size={iconSize} />,
                    action: () => handleBoost(booster),
                    cost: 1,
                    nextLevel: 1,
                    description: 'Fills your battery to maximum level',
                    title: 'Full Tank',
                    costAndLevel: () => <span> Free </span>,
                    disabled: tank[0] <= 0,
                    disableText: 'You used all of this booster. you have to wait to refill booster',
                })
                break;
            default:
                break;
        }
        toggleBooleanValue();

    }
    const { theme } = useTheme();
    return (
        <StyledGameUI className='main-section'>
            <section id="info">
                <span> Your Share balance </span>
                <strong className='balance'>$<CountUp start={prevInventory} end={inventory} /></strong>
            </section>

            <section id='boosters' className='flex flex-v topline-divider' data-theme={theme}>
                <div className="group" style={{ marginTop: '30px' }}>
                    <span>Your Daily Boosters</span>
                    <div className="flex flex-h" id='daily-boosters'>
                        <button className="game-box gold-text clickable" onClick={() => handleDrawer('tapping-guru')}>
                            <span>Tapping Guru</span>
                            <span>{guru.join('/')}</span>
                        </button>
                        <button className="game-box gold-btn clickable" onClick={() => handleDrawer('full-tank')}>
                            <span>Full Tank</span>
                            <span>{tank.join('/')}</span>
                        </button>
                    </div>
                </div>
                <div className="group flex flex-v scroll-fade" id='level-up'>
                    <span>Boosters</span>
                    <div className="flex flex-v custom-scroll" id='level-up-boosters'>
                        <button className="game-box caret clickable" onClick={() => handleDrawer('multitap')}>
                            <MultitapIcon height={20} width={20} />
                            {/* <FaHandPointUp /> */}
                            Multitap
                            <BsChevronRight size={18} />
                        </button>
                        <button className="game-box gold-icon caret clickable" onClick={() => handleDrawer('storage')}>
                            <FlashIcon height={20} width={20} />
                            {/* <FaBolt /> */}
                            Energy Limit
                            <BsChevronRight size={18} />
                        </button>
                        <button className="game-box caret clickable" onClick={() => handleDrawer('recharge')} >
                            <HourGlassIcon height={20} width={20} />
                            {/* <BsHourglassSplit /> */}
                            Recharging Speed
                            <BsChevronRight size={18} />
                        </button>
                        <button className="game-box caret clickable" onClick={() => handleDrawer('bot')} disabled={tappingBot.is_active}>
                            <RobotIcon height={20} width={20} />
                            {/* <BsRobot /> */}
                            Tap Bot
                            <BsChevronRight size={18} />
                        </button>
                    </div>
                </div>
            </section>
            <Drawer icon={input?.icon} title={input?.title} nextLevel={input?.nextLevel} cost={input?.cost} description={input?.description} action={input?.action} costAndLevel={input?.costAndLevel} disabled={input?.disabled} disableText={input.disableText} />
        </StyledGameUI>
    )
}

export default Index