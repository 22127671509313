import React from 'react'
import Layout from '../pages/Layout'
import qrAddress from '../assets/images/qr-link.png';
import styled from 'styled-components';
const StyledQrPage = styled.div`
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 0 var(--h-padding);
    gap: 40px;

    h1{
        color: var(--text-color);
    }
    img{
        max-width: 60vw;
        background: #fff;
        border-radius: 25px;
        padding: 30px;
    }
`
function QrRedirectPage() {
    return (
        <Layout>
            <StyledQrPage className="flex flex-v">
                <h1>Leave the desktop. Mobile gaming rocks!</h1>
                <img src={qrAddress} />
            </StyledQrPage>
        </Layout>
    )
}

export default QrRedirectPage