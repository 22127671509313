import styled from 'styled-components';

const StyledGameUI = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;

    #info{
        height: 25%;
        max-height: 25%;
        overflow: hidden;
        padding: 0 15%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .referrals {
            background: -webkit-linear-gradient(0deg, #EDCE96, #A48148);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 40px;
            // line-height: 50px;
            margin-bottom: 15px;
        }
    }
    .referral-link{
        div{
            justify-content: space-between;

            button{
                padding: 12px;
            }
        }
    }
    section#referral-list{
        height: 75%;
        max-height: 75%;
        overflow: hidden;
        padding: 0 var(--h-padding);
        border-radius: 30px;
        
        .referral-data{
            height: 100%;

            .invite-group{
                margin: 10px 0;
                justify-content: space-between;
                align-items: center;
                .title{
                    color: var(--text-color);
                }
                button{
                    padding: 12px;
                    border-radius: 5px;
                    border: none;
                }
            }
            .invite-list{
                position: relative;
                // padding: 10px 0;
                padding-bottom: 20px;
                margin-top: 10px;
                margin-right: -10px;
                gap: 0;
                justify-content: flex-start;
                overflow-y: auto;
                height: 100%;
                padding-right: 5px;

                .message{
                    margin-top: 40px;

                    .icon{
                        svg{
                            fill: var(--text-color-1);
                        }
                    }
                    .message-text{
                        margin-top: 20px;
                        color: var(--text-color-1);
                        font-size: 1.2rem;
                    }
                }

                .referral-group{
                    padding: 10px;
                    .info{
                        justify-content: space-between;
                        .user-data{
                            text-align: left;
                            .top-part{
                                justify-content: space-between;
                            }
                            .title{
                                text-overflow: ellipsis;
                                overflow: hidden;
                                width: 60%;
                            }
                            .level{
                                align-items: center;
                                white-space: nowrap;
                                svg{
                                    vertical-align: middle;
                                    color: #edce96;
                                    margin: 4px;
                                }
                            }
                        }
                        .rate{
                            svg{
                                vertical-align: middle;
                                color: #edce96;
                                margin: 4px;
                            }
                        }
                    }
                    .progress{
                        padding: 5px 0;
                    }
                }
            }

        }

        
}
`

export { StyledGameUI }