import React from 'react'
import styled from 'styled-components'
const StyledProgressBar = styled.div<{ $grd1: string, $grd2: string, $percent: number }>`
    .wrapper{
        position: relative;
        .bar{
            margin: 0 0px;
            border-radius: 25px;
            height:16px;
            width:auto;
            background-color: var(--main-color);
            // box-shadow: -2px -2px 2px 0px #1f1f1f, 2px 2px 2px 1px #0e0e0e;
            overflow: hidden;
            border: 1px solid var(--main-color);
            .progress{
                z-index: 1;
                // margin: 1px 0px;
                background: ${p => `linear-gradient(90deg,${p.$grd1},${p.$grd2})`};
                width: ${p => p.$percent}%;
                height: 100%;
                // border-radius: 20px;
                transition: width 0.1s linear;

            }
        }
        
    }
`
function ProgressBar({ percent }: { percent: number }) {
    return (
        <StyledProgressBar $grd1='#EDCE96' $grd2='#A48148' $percent={percent} >
            <div className="wrapper">
                <div className="bar game-box">
                    <div className="progress">
                    </div>
                </div>
            </div>
        </StyledProgressBar>
    )
}

export default ProgressBar