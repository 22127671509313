import styled from 'styled-components';
import { AiFillCloseCircle } from 'react-icons/ai';
import { usePopup } from '../providers/Popup';


const StyledPopup = styled.div<{ $isOpen: boolean }>`
    /* height: ${props => props.$isOpen ? 'calc(100% - 30px)' : '0'}; */
    height: ${props => props.$isOpen ? 'auto' : '0'};
    opacity: ${props => props.$isOpen ? '1' : '0'};
    /* padding: ${props => props.$isOpen ? '30px var(--h-padding) 0' : '0 var(--h-padding)'}; */
    overflow: hidden;
    /* width: calc(100% - 2 * var(--h-padding)); */
    width: 100%;
    box-sizing: border-box;
    background: var(--blur-color);
    position: absolute;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    border-top:1px solid #7775;
    bottom: 0;
    backdrop-filter: blur(8px);
    // border-radius: 25px 25px 0 0;
    // box-shadow: 0px -2px 0px 2px #1f1f1f;
    /* gap: ${props => props.$isOpen ? '8%' : '0'}; */
    /* transition: all 0.2s ease; */
    z-index:11;
    .close-btn{
        position: absolute;
        margin: 10px;
        left: 0;
        top: 0;
    }

    button{
        padding: 12px 40px;
        font-size: var(--font-size-2);
        color: white;
        display:inline;
    }

`

function Popup() {

  const { isOpen, close, popup } = usePopup();

  return (
    <StyledPopup className='popup flex flex-v topline-divider' $isOpen={isOpen}>
      <div className="close-btn clickable" onClick={() => close()} >
        <AiFillCloseCircle size={30} style={{ color: 'var(--gold-dark)', pointerEvents: 'none' }} />
      </div>
      {popup}
    </StyledPopup>
  )
}

export default Popup