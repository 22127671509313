import { useEffect, useState } from 'react'
import { StyledGameUI } from './styled.page'
import coin from '../../assets/images/zizo-coin-64.png';
import starFill from '../../assets/images/star-fill.png';
import starEmpty from '../../assets/images/star-empty.png';

import { useTheme } from '../../providers/Theme';
import { useAppDispatch, useAppSelector } from '../../hooks';
import RestAPIClass from '../../services/rest.service';
import { User } from '../../types/User';
import { max as MathMax, min as MathMin, normalizeValue, toNumberFormat } from '../../features/common';
import toast from 'react-hot-toast';
import { errorHandler } from '../../features/gameFunctions';
import Tabs from '../../components/Tabs';


function TopUsers() {
    const { theme } = useTheme();
    const [activeTab, setActiveTab] = useState<number>(0);
    // const navigate= (url:string)=>{}
    const dispatch = useAppDispatch()
    const { jwt } = useAppSelector(state => state.security);
    const [topPlayers, setTopPlayers] = useState<User[]>([]);
    const [topRichPlayers, setTopRichPlayers] = useState<User[]>([]);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0)
    useEffect(() => {
        let rest = new RestAPIClass(jwt);
        rest.GetTopUsers('inventory').then(response => {
            if (response.status === 'success') {
                setTopRichPlayers(response.data);
                let lowUser = MathMin(response.data, user => user.inventory);
                let topUser = MathMax(response.data, user => user.inventory);
                setMin(lowUser?.inventory ?? 0);
                setMax(topUser?.inventory ?? 0)
            } else {
                errorHandler(response.error, 'Could not load top rich players')
            }
        });

        rest.GetTopUsers('total_inventory').then(response => {
            if (response.status === 'success') {
                setTopPlayers(response.data);
                let lowUser = MathMin(response.data, user => user.total_inventory);
                let topUser = MathMax(response.data, user => user.total_inventory);
                setMin(lowUser?.total_inventory ?? 0);
                setMax(topUser?.total_inventory ?? 0)
            } else {
                errorHandler(response.error, 'Could not load top players')
            }
        });


    }, [])


    return (
        <StyledGameUI className='main-section'>
            <section id="info">
                <h1 className='title gold-text'> Ranking </h1>
            </section>
            <div id='player-list' className="flex flex-v custom-scroll">
                <Tabs tabs={["XP", "Wealth"]} activeTab={activeTab} setActiveTab={(i) => setActiveTab(i)} />
                <div className="list">
                    {[
                        topPlayers.map((i, k) => {
                            let stars = normalizeValue(i.total_inventory, min, max, 1, 5);
                            return <div key={k} className="player game-box flex flex-h">
                                <div className="rank gold-text">{(k + 1).toString().padStart(2, '0')}</div>
                                <div className="wrapper">
                                    <div className="outer-circle game-box">
                                        <div className="inner-circle game-box">
                                            <img src={coin} alt="Inner Circle Image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="details flex flex-v">
                                    <span className='title'>{i.username}&nbsp;</span>
                                    <span className='reward'>{toNumberFormat(i.total_inventory)}</span>
                                    <span className='flex flex-h stars'>{
                                        Array.from(Array(5)).map((i, j) => {
                                            return <img key={j} src={j < stars ? starFill : starEmpty} />
                                        })
                                    }</span>
                                </div>
                            </div>
                        }),
                        topRichPlayers.map((i, k) => {
                            let stars = normalizeValue(i.inventory, min, max, 1, 5);
                            return <div key={k} className="player game-box flex flex-h">
                                <div className="rank gold-text">{(k + 1).toString().padStart(2, '0')}</div>
                                <div className="wrapper">
                                    <div className="outer-circle game-box">
                                        <div className="inner-circle game-box">
                                            <img src={coin} alt="Inner Circle Image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="details flex flex-v">
                                    <span className='title'>{i.username}&nbsp;</span>
                                    <span className='reward'>{toNumberFormat(i.inventory)}</span>
                                    <span className='flex flex-h stars'>{
                                        Array.from(Array(5)).map((i, j) => {
                                            return <img key={j} src={j < stars ? starFill : starEmpty} />
                                        })
                                    }</span>
                                </div>
                            </div>
                        }),
                    ][activeTab]}
                </div >
            </div >
        </StyledGameUI >
    )
}

export default TopUsers