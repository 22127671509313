import toast from "react-hot-toast";
import { InviteRewardPlan, InviteRewardPlanJson, LeagueRewardPlan, LeagueRewardPlanJson, UserRewardPlan } from "../types/RewardPlan"
import TrophyLevel from "../types/TrophyLevel";
import { clamp } from "./common";

function parseInvitePlan(plan: InviteRewardPlan) {
    return {

    }
}
function parseLeaguePlan(data: UserRewardPlan) {
    let plan = data.rewardPlan;
    let json: LeagueRewardPlanJson = JSON.parse(plan.json_data);
    return {
        ...plan,
        ...json,
        // from: plan.count
    }
    return {
        id: plan.id,
        reward: plan.reward,
        title: json.title,
        count: json.count,
    }
}
function leagueData(levels: TrophyLevel[], current: number) {
    const leagueIndex = clamp(0, levels.length - 1, levels.findIndex(i => i.min <= current && current < i.max));
    console.log({ leagueIndex, current });

    const league = levels[leagueIndex];
    return { league, leagueIndex };
}

function errorHandler(error?: Error | string | object | null, message?: string) {
    if (error instanceof Error)
        toast(error?.message, { className: 'game-box error' });
    else if (typeof error === 'object')
        toast(JSON.stringify(error), { className: 'game-box error' });
    else if (typeof error === 'string')
        toast(error, { className: 'game-box error' });
    else
        toast(message ?? 'unhandled error', { className: 'game-box error' });
}

export {
    parseInvitePlan,
    parseLeaguePlan,
    leagueData,
    errorHandler
}