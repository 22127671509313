import { Axios, AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import PlayerStatus from "../types/PlayerStatus";
import Response from "../types/Response";
import InitialData from "../types/InitialData";
import { EarnPlan, GroupEarnPlan } from "../types/EarnPlan";
import { CoinLevel } from "../types/CoinLevel";
import { TotalEnergyLevel } from "../types/TotalEnergyLevel";
import { SkipEnergyLevel } from "../types/SkipEnergyLevel";
import { User } from "../types/User";
import { RewardPlan, UserRewardPlan } from "../types/RewardPlan";
import { UserClaim } from "../types/UserClaim";
import { Statistics } from "../types/Statistics";
import { UserEarnPlanResponse } from "../types/ResponseTypes";



class RestAPIClass {

    base_url: string;
    fallback_url: string;
    telegram_token: string;
    axios: Axios;
    constructor(jwt: string) {
        this.base_url = process.env.REACT_APP_BASE_API_URL || 'http://localhost:4000/';
        this.fallback_url = process.env.REACT_APP_FALLBACK_API_URL || 'http://localhost:4000/';
        // const initData = "query_id=AAEql4kGAAAAACqXiQYorvqG&user=%7B%22id%22%3A109680426%2C%22first_name%22%3A%22hamzeloo%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22hamzeloali%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1715792515&hash=af1a86205885c5de632bbde4fff3085dbb1fce6a2a44bbd60f6b7cdd9fa4db03";
        //#tgWebAppData=query_id%3DAAHJuYwMAAAAAMm5jAweYuKf%26user%3D%257B%2522id%2522%253A210549193%252C%2522first_name%2522%253A%2522.%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522dishdaradidan%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522allows_write_to_pm%2522%253Atrue%257D%26auth_date%3D1718910187%26hash%3D0918034fb347fc479d9f41bf2d0091d1c5b18b21506d624228ce3ab05cbb6a55&tgWebAppVersion=7.4&tgWebAppPlatform=web&tgWebAppThemeParams=%7B%22bg_color%22%3A%22%23ffffff%22%2C%22button_color%22%3A%22%233390ec%22%2C%22button_text_color%22%3A%22%23ffffff%22%2C%22hint_color%22%3A%22%23707579%22%2C%22link_color%22%3A%22%2300488f%22%2C%22secondary_bg_color%22%3A%22%23f4f4f5%22%2C%22text_color%22%3A%22%23000000%22%2C%22header_bg_color%22%3A%22%23ffffff%22%2C%22accent_text_color%22%3A%22%233390ec%22%2C%22section_bg_color%22%3A%22%23ffffff%22%2C%22section_header_text_color%22%3A%22%233390ec%22%2C%22subtitle_text_color%22%3A%22%23707579%22%2C%22destructive_text_color%22%3A%22%23df3f40%22%7D
        let hash = new URLSearchParams(Telegram.WebApp.initData);
        hash.get('tgWebAppData');
        this.telegram_token = hash.toString();
        this.axios = new Axios({
            baseURL: this.base_url,
            responseType: "json",
            transitional: {
                forcedJSONParsing: true,
            },
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "x-auth": this.telegram_token,
                Authorization: `token ${jwt}`
            }
        });


        // const requestInterceptor = this.axios.interceptors.request.use(
        //     (config: InternalAxiosRequestConfig) => {
        //     //   setLoading(true);
        //       console.log('Request Sent:', config);
        //       return config;
        //     },
        //     (error) => {
        //     //   setLoading(false);
        //       console.error('Request Error:', error);
        //       return Promise.reject(error);
        //     }
        //   );

        //   const responseInterceptor = this.axios.interceptors.response.use(
        //     (response: AxiosResponse) => {
        //     //   setLoading(false);
        //       console.log('Response Received:', response);
        //       return response;
        //     },
        //     (error) => {
        //     //   setLoading(false);
        //       console.error('Response Error:', error);
        //       return Promise.reject(error);
        //     }
        //   );
    }


    __responseHandler(response: any) {
        if (response && response.data) {

            return JSON.parse(response.data)
        }
        else {

            throw new Error("Server error occured");
        }
    }


    __useFallbackServer(error: any) {
        console.log(this);
        let baseURL = this.fallback_url;
        let url = error.config.url.replace(this.base_url, this.fallback_url);
        return this.axios.request({ ...error.config, baseURL, url }).then(this.__responseHandler).catch(this.__errorHandler);
    }

    __errorHandler(error: any) {
        console.log(error);
        return { status: 'failed', message: 'Axios error' }
    }

    ServerError(e?: string | Error): Response<null> {
        return { status: "error", error: e?.toString() ?? "Server root not found", data: null }
    }
    async Register(): Promise<Response<{ jwt: string }>> {
        return this.axios.post('api/auth/register', JSON.stringify({ telegram_token: this.telegram_token })).then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async GetTappingLevels(): Promise<Response<CoinLevel[]>> {
        return this.axios.get('api/coinLevel').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async GetStorageLevels(): Promise<Response<TotalEnergyLevel[]>> {
        return this.axios.get('api/totalEnergyLevel').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async GetSpeedLevels(): Promise<Response<SkipEnergyLevel[]>> {
        return this.axios.get('api/skipEnergyLevel').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }
    async GetUserEarnPlans(): Promise<Response<UserEarnPlanResponse>> {
        return this.axios.get('api/earnPlan/userEarnPlans').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }
    async GetEarnPlans(): Promise<Response<Array<EarnPlan>>> {
        return this.axios.get('api/earnPlan').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }
    async GetGroupEarnPlans(): Promise<Response<Array<GroupEarnPlan>>> {
        return this.axios.get('api/groupEarnPlan').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }
    async GetRewardPlans(): Promise<Response<Array<RewardPlan>>> {
        return this.axios.get('api/rewardPlan').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async GetUserRewardPlans(): Promise<Response<Array<UserRewardPlan>>> {
        return this.axios.get('api/rewardPlan/getUserRewardPlans').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async GetUserClaims(): Promise<Response<Array<UserClaim>>> {
        return this.axios.get('/api/userClaim/userClaims').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async ClaimReward(id: number): Promise<Response<any>> {
        return this.axios.post('/api/userClaim/payUserClaim', JSON.stringify({ userClaimId: id })).then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async ClaimSocialReward(id: number): Promise<Response<any>> {
        return this.axios.post('/api/userClaim/payUserClaim', JSON.stringify({ userClaimId: id })).then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async GetPlayerStatus(): Promise<Response<PlayerStatus>> {
        return this.axios.get('api/user/me').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async GetInitialData(): Promise<Response<InitialData>> {
        return this.axios.get('api/game/initData').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async CheckSocialTask(task_id: number): Promise<Response<boolean>> {
        return this.axios.post(`api/earnPlan/submit`, JSON.stringify({ earnPlanId: task_id })).then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async UpgradeMultiTap(): Promise<Response<number>> {
        return this.axios.post('api/coinLevel/submit').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }
    async UpgradeStorage(): Promise<Response<number>> {
        return this.axios.post('api/totalEnergyLevel/submit').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }
    async UpgradeRecharge(): Promise<Response<number>> {
        return this.axios.post('api/skipEnergyLevel/submit').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }
    async ActivateBot(): Promise<Response<number>> {
        return this.axios.post('api/game/activeRobotBooster').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }
    async ActivateGuru(): Promise<Response<number>> {
        return this.axios.post('api/game/increaseCountPerSnapBooster').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }
    async FillTank(): Promise<Response<number>> {
        return this.axios.post('api/game/fillingTotalEnergyBooster').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async GetReferrals(): Promise<Response<Array<User>>> {
        return this.axios.get('api/invite/getUserInviteList').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async GetInviteLink(): Promise<Response<{ url: string }>> {
        return this.axios.get('api/invite/getReferralCode').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler);
    }

    async GetGameStatistics(): Promise<Response<Statistics>> {
        return this.axios.get('api/statistical').then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler).catch(e => {
            return
        });
    }
    async GetTopUsers(type: 'inventory' | 'total_inventory'): Promise<Response<User[]>> {
        return this.axios.get('api/statistical/topUsers?type='+type).then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler).catch(e => {
        // return this.axios.get('api/statistical/topUsers', { params: { type } }).then(this.__responseHandler).catch(error => this.__useFallbackServer(error)).catch(this.__errorHandler).catch(e => {
            return this.ServerError(e);
        });
    }

}

// const rest = new RestAPIClass()
export default RestAPIClass;

