import React from 'react'

type GradientTextInput = {
    grad_a: string,
    grad_b: string,
    text: string,
    component?: string,
    className?: string
}


function GradientText({ grad_a, grad_b, text, component, className }: GradientTextInput) {
    return (
        <span
        className={`${className} gold-text`}
        >
            {text}
        </span>
    )
}

export default GradientText