import React from 'react'
import styled from 'styled-components';
import coin from '../assets/images/zizo-coin-64.png';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useBoolean } from '../hooks/DrawerHandler';
import { toNumberFormat } from '../features/common';
import { useAppSelector } from '../hooks';

export type DrawerInput = { icon: React.ReactElement, title: string, description: string, cost: number, nextLevel: number, action: Function, costAndLevel?: Function, disabled: boolean, disableText: string };
type StyledDrawerInput = { $isOpen: boolean }
const StyledDrawer = styled.div<StyledDrawerInput>`
    height: ${props => props.$isOpen ? 'calc(79% - 30px)' : '0'};
    opacity: ${props => props.$isOpen ? '1' : '0'};
    padding: ${props => props.$isOpen ? '30px var(--h-padding) 0' : '0 var(--h-padding)'};
    overflow: hidden;
    width: calc(100% - 2 * var(--h-padding));
    background: var(--blur-color);
    position: absolute;
    align-content: center;
    justify-content: center;
    bottom: 0;
    backdrop-filter: blur(16px);
    // border-radius: 25px 25px 0 0;
    // box-shadow: 0px -2px 0px 2px #1f1f1f;
    gap: ${props => props.$isOpen ? '8%' : '0'};
    transition: all 0.2s ease;

    .close-btn{
        position: absolute;
        margin: 10px;
        left: 0;
        top: 0;
    }
    .group{
        .icon{
            svg{
                fill: var(--menu-btn-color);
            }
        }
            gap: 10px;
            justify-content: space-between;
            .title{
                font-size: 30px;
                font-weight: bold;
            }
            .divider{
                height: 10px;
            }
            .description{
                color: #b0aeae;
            }
        }
        .cost-level{
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-size: 20px;

            img{
                height: 40px;
            }
        }

        button{
            padding: 12px 16px;
            font-size: var(--font-size-2);
            color: white;
        }

`

function Drawer({ icon, title, description, cost, nextLevel, action, costAndLevel, disabled: isMax, disableText }: DrawerInput) {
    console.log({ isMax });

    const { booleanValue, toggleBooleanValue } = useBoolean();
    const { inventory } = useAppSelector(state => state.full);
    return (
        <StyledDrawer className='flex flex-v topline-divider' $isOpen={booleanValue}>
            <div className="close-btn clickable" onClick={() => toggleBooleanValue()} >
                <AiFillCloseCircle size={30} style={{ color: 'var(--gold-dark)', pointerEvents: 'none' }} />
            </div>
            <div className="group flex flex-v">
                <div className='icon'>
                    {icon}
                </div>
                <div className="title gold-text">
                    {title}
                </div>
                <div className="divider game-box"></div>
                <div className="description">
                    {description}
                </div>
            </div>
            <div className="cost-level gold-text flex flex-h">
                <img src={coin} />
                {costAndLevel ? costAndLevel() : <>
                    <span> {toNumberFormat(cost)} </span>
                    <span> | </span>
                    <span> {nextLevel} Level </span>
                </>
                }
            </div>
            {isMax ? <p>{disableText}</p> :
                <button className="game-box clickable" onClick={() => action()} disabled={inventory < cost}>
                    {inventory < cost ? "Insufficient Funds" : "Get it!"}
                </button>
            }
        </StyledDrawer>
    )
}

export default Drawer