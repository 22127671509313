import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for the context value
interface BooleanContextType {
  booleanValue: boolean;
  toggleBooleanValue: () => void;
}

// Create a context for the boolean value
const BooleanContext = createContext<BooleanContextType | undefined>(undefined);

// Custom hook to access the boolean value
export const useBoolean = (): BooleanContextType => {
  const context = useContext(BooleanContext);
  if (!context) {
    throw new Error('useBoolean must be used within a BooleanProvider');
  }
  return context;
};

// Provider component to set and manage the boolean value
interface BooleanProviderProps {
  children: ReactNode;
}

export const BooleanProvider: React.FC<BooleanProviderProps> = ({ children }) => {
  const [booleanValue, setBooleanValue] = useState<boolean>(false);

  // Function to toggle the boolean value
  const toggleBooleanValue = () => {
    setBooleanValue(prevValue => !prevValue);
  };

  const value: BooleanContextType = {
    booleanValue,
    toggleBooleanValue
  };

  return (
    <BooleanContext.Provider value={value}>
      {children}
    </BooleanContext.Provider>
  );
};
