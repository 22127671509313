import React from 'react'
import styled from 'styled-components';

const StyledDiv = styled.div`
    display: flex;
    gap:1px;
    justify-content: center;
    margin-bottom: 10px;
    border: 2px solid var(--main-color);
    background: var(--secondary-color);
    button{
        padding: 12px 12px;
        text-align: center;
        width: 100%;
        // padding: 7px 0px 7px 0;
        font-weight: bold;
        border: none;
        border-radius: 5px;
    }
`

type TabsProps =
    {
        tabs: string[];
        activeTab: number;
        setActiveTab: (i: number) => void
    }


function Tabs({ tabs, activeTab, setActiveTab }: TabsProps) {
    return (
        <StyledDiv className="tabs game-box">
            {tabs.map((i, k) => {
                return <button key={k} className={'clickable ' + (activeTab == k ? 'gold-btn' : 'gold-text')} onClick={() => setActiveTab(k)}>{i}</button>
            })}
        </StyledDiv>
    )
}

export default Tabs