import React, { createContext, useState, useContext, ReactNode } from 'react';


// Define the shape of the context
interface ThemeContextType {
  theme: string;
  setTheme: (theme: string) => void;
}

// Create a default theme
const defaultTheme: string = 'light';

// Create the context
const ThemeContext = createContext<ThemeContextType>({
  theme: defaultTheme,
  setTheme: () => { },
});

// Custom hook to access the theme context
export const useTheme = () => useContext(ThemeContext);

// Provider component to set and manage the boolean value
interface ThemeProviderProps {
  children: ReactNode;
}
// Theme Provider component
export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<string>(defaultTheme);

  const handleSetTheme = (newTheme: string) => {
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme: handleSetTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
