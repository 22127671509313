import styled from 'styled-components';

const StyledGameUI = styled.div`
    // height:70%;
    // flex:1;
    overflow: hidden;
    // background: orange;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // opacity: 0.6;
    --r: 15deg;
    padding: 0 var(--h-padding);

    section#info{
        height: 27%;
        max-height: 27%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:7%;
        
        .user-level{
            height: 35px;
            width:90px;
            color: var(--gold-dark);
            font-size: large;
            vertical-align: middle;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .balance {
            // padding: 30px 0 0px 0;
            background: -webkit-linear-gradient(0deg, #EDCE96, #A48148);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            // line-height: 40px;
            // margin-bottom: 45px;
            span{
                font-size: 50px;
            }
        }
    }

    section#coin{
        height: 47%;
        max-height: 47%;
        overflow: visible;
        perspective: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        img,.fire{
            max-height: 92%;
            max-width: 92%;
            // opacity: 0.5;

            // margin: 20px 30px;
            // border:1px solid gray;
            border-radius:50%;
            // transform-style:preserve-3d;
            // transition: transform 0.01s ease;
            z-index: 1;
        }
        canvas{
            min-height: 100%;
            min-width:100%;
        }
        .fire{
            position: absolute;
            z-index:2;
            pointer-events:none;
            transform: scale(1.2);
        }
        img.disabled{
            opacity:0.6;
        }
        img.tapped{
            transform: scale(0.98);
        }
        img.ne{
            transform: rotate3d(1,1,0, var(--r));
        }
        
        img.nw{
            transform: rotate3d(1,-1,0,var(--r));
        }
        
        img.sw{
        transform: rotate3d(-1,-1,0,var(--r));
        }
    
        img.se{
            transform: rotate3d(-1,1,0,var(--r));
        }
        
        img.n{
            transform: rotate3d(1,0,0,var(--r));
        }
        img.s{
            transform: rotate3d(-1,0,0,var(--r));
        }
        
        img.e{
            transform: rotate3d(0,1,0,var(--r));
        }

        img.w{
            transform: rotate3d(0,-1,0,var(--r));
        }
    }
    section#progress{
        height: 26%;
        max-height: 26%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: strech;
        gap:4px;

    }
}
`

export { StyledGameUI }