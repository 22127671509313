import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import CountUp from 'react-countup';
import { UpdateRef } from '../types/Interface';

type CountUpProps = {
    prev_inventory: number;
    inventory: number;
    per_tap: number;
}

const CustomCountUp = forwardRef((props: CountUpProps, ref: Ref<UpdateRef<number>>) => {

    const [inventory, setInventory] = useState({ prev: props.prev_inventory, current: props.inventory })

    useImperativeHandle(ref, () => ({
        update(newValue) {
            // console.log('countup');
            if (newValue == inventory.current)
                return;

            setInventory(i => ({ prev: i.current, current: newValue }));
        }
    }));


    return <CountUp start={inventory.prev} end={inventory.current} useEasing={false} duration={1} />
});

export default CustomCountUp