import { createSlice, SliceCaseReducers, SliceSelectors } from "@reduxjs/toolkit";
import RewardPlans, { RefTask, SubTask } from "../types/RefTask";
import { InviteRewardPlan, InviteRewardPlanJson, LeagueRewardPlan, LeagueRewardPlanJson, RewardPlan, UserRewardPlan } from "../types/RewardPlan";
import { EarnPlan, GroupEarnPlan } from "../types/EarnPlan";

export const refTaskSlice = createSlice<RewardPlans, SliceCaseReducers<RewardPlans>, string, SliceSelectors<RewardPlans>, string>({
    name: 'refTask',
    initialState: {
        activeRefTask: {
            title: 'task group title',
            description: 'this is the task description',
            hint: 'you can know about hint here',
            reward: 0,
            json_data: "",
            claim: null,
            tasks: [
                {
                    id: 1,
                    title: 'task title',
                    link: 'http://localhost:3000/#',
                    type: 'twitter_follow_account',
                    status: false,
                }
            ]
        },
        tasks: [],
        invites: [],
        leagues: []

    },
    reducers: {
        setActiveReftask: (state, action) => {
            return {
                ...state,
                activeRefTask: action.payload,
            };
        },
        setTasks: (state, action) => {
            return {
                ...state,
                tasks: action.payload.map((i: GroupEarnPlan) => {
                    // console.log({ groupEarnPlan: i });

                    let json: RefTask = JSON.parse(i.json_data)

                    return {
                        title: i.title,
                        reward: i.reward,
                        description: i.description,
                        hint: i?.hint ?? false,
                        claim: i.claim,
                        tasks: i.EarnPlans.map(j => {

                            let json = JSON.parse(j.json_data)
                            return {
                                id: j.id,
                                title: j.title,
                                type: j.type,
                                link: json?.link,
                                // checkLink: j.checkLink,
                                status: j.status ?? false,
                            }
                        }),

                    }
                })
            }
        },
        setInvites: (state, action) => {
            // console.log({ action });

            return {
                ...state,
                invites: action.payload.map((u: UserRewardPlan) => {

                    let i = u.rewardPlan
                    // console.log({ json: i.json_data });

                    let json: InviteRewardPlanJson = JSON.parse(i.json_data as string)

                    return {
                        id: i.id,
                        reward: i.reward,
                        title: json.title,
                        count: json.count,
                        status: u.status,
                        // claimStatus: u.claimStatus,
                        claimStatus: u.userClaim.length > 0 && u.userClaim[0].is_paid,
                        userClaim: u.userClaim
                        // status: json?.status ?? false,
                    }
                })
            }
        },
        setLeagues: (state, action) => {
            // console.log({ action });

            return {
                ...state,
                leagues: action.payload.map((u: UserRewardPlan, index: number, array: UserRewardPlan[]) => {
                    let i = u.rewardPlan;
                    let json: LeagueRewardPlanJson = JSON.parse(i.json_data as string)
                    return {
                        id: i.id,
                        reward: i.reward,
                        title: json.title,
                        count: json.count,
                        status: u.status,
                        // claimStatus: u.claimStatus,
                        image: json.image,
                        claimStatus: u.userClaim.length > 0 && u.userClaim[0].is_paid,
                        userClaim: u.userClaim
                    }
                }).map((i: any, index: number, array: any) => {
                    let min = i.count;
                    let max = index + 1 < array.length ? array[index + 1].count : min * 2;
                    return {
                        ...i,
                        min,
                        max
                    }
                })
            }
        },
        updateReftask: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        }
    }
});
export const { setActiveReftask, setTasks, setInvites, setLeagues, updateReftask } = refTaskSlice.actions;
export default refTaskSlice.reducer