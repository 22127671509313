import React, { forwardRef, Ref, useCallback, useEffect, useRef, useState } from 'react'
import coin from '../assets/images/zizo-coin.png';

type coinButtonProps = {
    disabled: boolean,
    // handleTouchStart: React.TouchEventHandler<HTMLImageElement>
    // handleTouchEnd: React.TouchEventHandler<HTMLImageElement>
    // handleTouchCancel: React.TouchEventHandler<HTMLImageElement>
    Tap: (x: number, y: number) => void
}

const CoinButton = forwardRef((props: coinButtonProps, ref: Ref<HTMLImageElement>) => {
    const [tapped, setTapped] = useState(false);
    const activeTouchesRef = useRef<Set<number>>(new Set());
    const [touches, setTouches] = useState<number[]>([]);

    const handleTouchStart = useCallback((e: React.TouchEvent) => {

        for (let touch of Array.from(e.changedTouches)) {
            if (!activeTouchesRef.current.has(touch.identifier)) {
                activeTouchesRef.current.add(touch.identifier);
                setTouches((prevTouches) => [...prevTouches, touch.identifier]);
                // Fire your function here
                props.Tap(touch.clientX, touch.clientY);
                // console.log('New touch started:', touch.identifier);

            }
        }
    }, []);

    const handleTouchEnd = useCallback((e: React.TouchEvent) => {
        for (let touch of Array.from(e.changedTouches)) {
            activeTouchesRef.current.delete(touch.identifier);
            setTouches((prevTouches) => prevTouches.filter(id => id !== touch.identifier));
        }
    }, []);

    const handleTouchCancel = useCallback((e: React.TouchEvent) => {
        for (let touch of Array.from(e.changedTouches)) {
            activeTouchesRef.current.delete(touch.identifier);
            setTouches((prevTouches) => prevTouches.filter(id => id !== touch.identifier));
        }
    }, []);

    return <img src={coin}
        ref={ref}
        draggable={false}
        // className={'alt ' + (props.disabled ? 'disabled ' : '') + (tapped ? 'tapped ' : '')}// + (classes[current % classes.length])}
        className={'alt ' + (tapped ? 'tapped ' : '')}// + (classes[current % classes.length])}
        // onClick={handleClick}
        onMouseDown={() => setTapped(true)}
        onMouseUp={() => setTapped(false)}
        onTouchStart={(e) => {
            // handleTap(e);
            handleTouchStart(e);
            setTapped(true);
        }}
        // onTouchStart={handleTap}
        onTouchEnd={(e) => {
            handleTouchEnd(e);
            setTapped(false);
        }}
        onTouchCancel={handleTouchCancel}
    // style={style}
    />
});

export default CoinButton