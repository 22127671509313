import { GlobalStyle } from './GlobalStyle';
import { useTheme } from '../providers/Theme';
import style from './style.module.css';
import { BsInfoCircleFill, BsMoonFill, BsSunFill } from 'react-icons/bs';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import Popup from '../components/Popup';

Layout.defaultProps = {
    hasFooter: true
}
function Layout({ hasFooter, children }: { hasFooter: boolean, children: any }) {
    const { theme, setTheme } = useTheme();
    const handleTheme = () => {
        let t = theme == 'dark' ? 'light' : 'dark';
        localStorage.setItem('theme', t);
        setTheme(t);
    }

    useEffect(() => {
        setTheme(localStorage.getItem('theme') ?? 'light');
    }, [])

    return (
        <>
            <GlobalStyle data-theme={theme} $dark={theme === 'dark'} $shadow={theme == 'dark' ? { high: '#1f1f1f', low: '#0e0e0e' } : { high: '#ffffffb3', low: '#00000066' }} />
            <div className={style.wrapper} data-theme={theme}>
                <div className={style.main + (!hasFooter ? ' main-no-footer' : '')}>
                    <button className="theme-changer game-box clickable" onClick={handleTheme}>
                        {theme === 'dark' ? <BsSunFill size={16} /> : <BsMoonFill size={16} />}
                    </button>
                    {/* <button className="clipboard game-box" onClick={() => {
                        // let text = Telegram.WebApp.initData;
                        // navigator.clipboard.writeText(text);
                        alert('You are playing on ' + ['android', 'ios'].includes(Telegram.WebApp.platform) ? 'mobile' : 'non-mobile' + ' platform');
                    }}>
                        {<BsInfoCircleFill />}
                    </button> */}
                    {children}
                    <Toaster position={'bottom-center'} />
                    <Popup />
                </div>
            </div>
        </>
    )
}

export default Layout
