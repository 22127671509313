import React, { createContext, useState, useContext, ReactNode, ReactElement } from 'react';


// Define the shape of the context
interface PopupContextType {
  popup: ReactElement;
  setPopup: (popup: ReactElement) => void;
  isOpen: boolean;
  close: () => void;
  show: () => void;
}

// Create a default popup
const defaultPopup: ReactElement = <div>Default popup content</div>;

// Create the context
const PopupContext = createContext<PopupContextType>({
  popup: defaultPopup,
  setPopup: () => { },
  isOpen: false,
  close: () => { },
  show: () => { },
});

// Custom hook to access the popup context
export const usePopup = () => useContext(PopupContext);

// Provider component to set and manage the boolean value
interface PopupProviderProps {
  children: ReactNode;
}
// Popup Provider component
export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const [popup, setPopup] = useState<ReactElement>(defaultPopup);
  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);
  const show = () => setIsOpen(true);
  return (
    <PopupContext.Provider value={{ popup, setPopup, isOpen, close, show }}>
      {children}
    </PopupContext.Provider>
  );
};
