import styled from 'styled-components';

const StyledGameUI = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;

    #info{
        height: 25%;
        max-height: 25%;
        overflow: hidden;
        padding: 0 15%;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8%;

        span {
            color: var(--text-color-1);
            font-weight:bold;
            align-self: flex-start;
        }

        .balance {
            background: -webkit-linear-gradient(0deg, #EDCE96, #A48148);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 60px;
            // line-height: 50px;
            // margin-bottom: 15px;
        }
    }

    section#stats{
        height: 75%;
        max-height: 75%;
        overflow: hidden;
        padding: 0 var(--h-padding);
        border-radius: 30px;
        gap: 0px;
        jsutify-content: flex-start;
        .divider{
            display: flex;
            gap:1px;
            justify-content: center;
            border: 2px solid var(--main-color);
            background: var(--main-color);
        }

        button{
            padding:10px;
        }
        .stats-data{
            position: relative;
            padding: 5px 0;
            gap: 4px;
            justify-content: space-around;
            overflow-y: auto;
            padding-right: 5px;
            background-color: var(--secondary-color);
            .stat-group{
                padding: 12px 25px;
                // opacity: 0.5;
                justify-content: space-between;
                gap: 15px;
                
                        .title{
                            color: var(--text-color);
                            font-size: 20px;
                            font-weight: bold;
                        }
                        .amount{
                            color: #ccc;
                            font-size: 20px;
                            font-weight: bold;

                }
            }
        }
}
`

export { StyledGameUI }