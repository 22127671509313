import { useEffect } from 'react';
import toast from 'react-hot-toast';

// Custom hook to prevent Telegram mini apps from collapsing
const usePreventTelegramCollapse = () => {
    useEffect(() => {
        const preventDefault = (e: TouchEvent) => {
            e.preventDefault();
        };

        const captureTouchEvents = (e: TouchEvent) => {
            let target: EventTarget | null = e.target;

            // Log the event and its parent elements
            while (target && target instanceof HTMLElement) {
                // console.log('Touch event on:', target);
                // console.log(target.classList);
                
                if(target.classList.contains('custom-scroll'))
                    return;
                if(target.classList.contains('clickable'))
                    return;
                target = target.parentElement;
            }
            e.preventDefault();
            e.stopPropagation();
            // toast('stop event:' + e.type)
        };

        // Add event listeners to prevent default touch behaviors
        document.addEventListener('touchstart', captureTouchEvents, { passive: false });
        document.addEventListener('touchmove', captureTouchEvents, { passive: false });
        document.addEventListener('touchend', captureTouchEvents, { passive: false });

        return () => {
            // Remove event listeners on cleanup
            document.removeEventListener('touchstart', captureTouchEvents);
            document.removeEventListener('touchmove', captureTouchEvents);
            document.removeEventListener('touchend', captureTouchEvents);
        };
    }, []);
};

export default usePreventTelegramCollapse;
