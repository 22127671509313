import React from 'react'
import Layout from '../pages/Layout'
import coin from '../assets/images/zizo-coin-64.png';
import styled from 'styled-components';

const StyledDiv = styled.div`
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .circle{
        position: absolute;
        background: #fff3;
        backdrop-filter: blur(5px);
        height: 64px;
        width: 64px;
        border-radius: 50%;
        animation: swing 2s ease-out 0s infinite;
    }
    .coin{
        position: absolute;
        height: 64px;
        width: 64px;
        animation: swing 2s ease-out 1s infinite;
    }
`

function Loading() {
    return (
        <Layout>
            <StyledDiv className="flex">
                {/* <div style={{ color: 'var(--text-color)' }}>Loading...</div> */}
                <img className='coin' src={coin} />
                <div className='circle'></div>

            </StyledDiv>
        </Layout>
    )
}

export default Loading