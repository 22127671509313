import React from 'react'
import { BsRobot } from 'react-icons/bs';
import styled from 'styled-components';
const StyledDiv = styled.div`
    justify-content: center;
    align-items: stretch;
    color: var(--text-color);
    padding: 20px;
    gap: 10px;

    .title{
        font-size: 1.8rem;
    }

    .text{
        padding: 0px 20px;
    }

    .profit{
        font-size: 1.4rem;
    }

`

type ProfitPopupProps = {
    profit: number;
    onCollect: (profit: number) => void
}

function ProfitPopup({ profit, onCollect }: ProfitPopupProps) {
    return <StyledDiv className='flex flex-v'>
        <div className='title'>Tap Bot <BsRobot /></div>
        <div className='text'>
            While you were away your bot gets you some zizo coins 
        </div>
        <div className='profit'>{profit}</div>
        <button className='clickable game-box' onClick={() => onCollect(profit)}>Collect</button>
    </StyledDiv>

}

export default ProfitPopup