import { StyledFooter } from './styled.footer';
import { AiFillAppstore, AiFillHome, AiFillSetting, AiOutlineThunderbolt } from "react-icons/ai";
import { BsCardChecklist, BsShareFill, BsTelegram } from "react-icons/bs";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setActivePage } from '../../features/pageSlice';
import { ReactComponent as HomeIcon } from '../../assets/icons/menu-home.svg'
import { ReactComponent as SettingIcon } from '../../assets/icons/setting.svg'
import { ReactComponent as DollarIcon } from '../../assets/icons/dollar-coin.svg'
import { ReactComponent as RocketIcon } from '../../assets/icons/rocket.svg'
import { ReactComponent as ChartIcon } from '../../assets/icons/chart.svg'
import { ReactComponent as TaskIcon } from '../../assets/icons/task-tick.svg'
import { ReactComponent as CheckList } from '../../assets/icons/check-list.svg'
import { ReactComponent as TeamWorkIcon } from '../../assets/icons/teamwork.svg'
const iconSize = 18;
function Index() {
  const { activePage } = useAppSelector(state => state.page);
  const dispatch = useAppDispatch();
  return (
    <StyledFooter className='footer-section'>
      <button
        className={'game-box' + (activePage == 'refs' ? ' active' : '')}
        onClick={() => dispatch(setActivePage('refs'))}
        onTouchStart={() => dispatch(setActivePage('refs'))}

      >
        {/* <AiFillSetting size={iconSize} /> */}
        <TeamWorkIcon height={24} width={24} />
        {/* <BsShareFill size={iconSize} /> */}
      </button>
      <button
        className={'game-box' + (activePage == 'tasks' ? ' active' : '')}
        onClick={() => dispatch(setActivePage('tasks'))}
        onTouchStart={() => dispatch(setActivePage('tasks'))}
      >
        <CheckList height={24} width={24} />
        {/* <BsCardChecklist size={iconSize} /> */}
      </button>
      <button
        className={'game-box' + (activePage == 'home' ? ' active' : '')}
        onClick={() => dispatch(setActivePage('home'))}
        onTouchStart={() => dispatch(setActivePage('home'))}
      >
        <DollarIcon height={24} width={24} />
        {/* <AiFillHome size={iconSize} /> */}
      </button>
      <button
        className={'game-box' + (activePage == 'boost' ? ' active' : '')}
        onClick={() => dispatch(setActivePage('boost'))}
        onTouchStart={() => dispatch(setActivePage('boost'))}
      >
        <RocketIcon height={24} width={24} />
        {/* <AiOutlineThunderbolt size={iconSize} /> */}
      </button>
      <button
        className={'game-box' + (['apps', 'top_users'].includes(activePage) ? ' active' : '')}
        onClick={() => dispatch(setActivePage('apps'))}
        onTouchStart={() => dispatch(setActivePage('apps'))}
      >
        <ChartIcon height={24} width={24} />
        {/* <AiFillAppstore size={iconSize} /> */}
      </button>
      {/* <button
        className={'game-box' + (activePage == 'stats' ? ' active' : '')}
        onClick={() => dispatch(setActivePage('stats'))}
      >
        <AiFillAppstore size={iconSize} />
      </button> */}
    </StyledFooter>
  )
}

export default Index