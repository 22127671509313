import styled from 'styled-components';

const StyledGameUI = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    #info{
        height: 25%;
        max-height: 25%;
        overflow: hidden;
        padding: 0 15%;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8%;

        span {
            color: var(--text-color-1);
            font-weight:bold;
            align-self: flex-start;
        }

        .balance {
            background: -webkit-linear-gradient(0deg, #EDCE96, #A48148);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 60px;
            // line-height: 50px;
            // margin-bottom: 15px;
        }
        @media (max-width: 380px) {
            .balance{
                font-size: 50px !important;
            }
        }
    }

    section#boosters{
        height: 75%;
        max-height: 75%;
        overflow: hidden;
        padding: 0 var(--h-padding);
        
        #level-up{
            position: relative;
            flex-grow: 1;
            overflow:hidden;
            #level-up-boosters{
            }
        }
        .group{
            text-align: left;
            margin-bottom: 10px;

            
            span{
                text-align: left;
                padding: 7px 0px 7px 0;
                color: var(--text-color-1);
                font-weight: bold;
            }
            div#daily-boosters{
                padding: 20px 0;
                // gap: 10px;
                justify-content: space-between;
                
                button{
                    font-weight: 800;
                    padding: 12px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    border-radius: var(--button-radius);
                }
                button.gold-text{
                    background: -webkit-linear-gradient(0deg, #EDCE96, #A48148);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                button.gold-btn{
                    background: -webkit-linear-gradient(0deg, #EDCE96, #A48148);
                    span{
                        color: black;
                    }
                }
            }

            div#level-up-boosters{
                padding: 15px 0;
                gap: 0px;
                flex-grow: 1;
                overflow:auto;
                button{
                    padding: 18px 12px;
                    text-align:left;
                    font-weight: 800;
                    color: var(--menu-btn-color);
                    border-radius: var(--button-radius);
                    
                    &:hover{
                        svg:first-child{
                            fill: var(--gold-light);
                        }
                    }
                    
                    svg{
                        fill: var(--menu-btn-color);
                        vertical-align: baseline;
                    }
                    
                    svg:first-child{
                        margin-right:10px;
                    }
                    svg:last-child {
                        float: right;
                        font-weight: 800;
                    }
                }

                button.gold-icon{
                    svg:first-child{
                        color:  #A48148;
                    }
                }
            }
        }
    }
`

export { StyledGameUI }