// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__rg3iR {
    /* max-height: var(--tg-viewport-height, 100vh); */
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #0e0e0e;
    width: 100vw;
    align-items: center;
    justify-content: center;
}

.style_main__8sl0a {
    position: relative;
    background-color: var(--main-color);

    height: 100vh;
    /* max-height: var(--tg-viewport-height); */
    max-height: 100vh;
    /* width: 46.18vh; */
    /* max-width: 56.25vh; */
    max-width: 66.7vh;

    width: 100vw;
    /* background-image: url('http://localhost:3000/images/game-page2.png'); */
    /* background-image: url('http://localhost:3000/images/game-boost.png'); */
    /* background-image: url('http://localhost:3000/images/game-task.png'); */
    /* background-image: url('http://localhost:3000/images/tp-d.png'); */
    /* background-repeat: no-repeat; */
    background-size: contain;
    transition: background-color var(--transition-duration);
}`, "",{"version":3,"sources":["webpack://./src/pages/style.module.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,mCAAmC;;IAEnC,aAAa;IACb,2CAA2C;IAC3C,iBAAiB;IACjB,oBAAoB;IACpB,wBAAwB;IACxB,iBAAiB;;IAEjB,YAAY;IACZ,0EAA0E;IAC1E,0EAA0E;IAC1E,yEAAyE;IACzE,oEAAoE;IACpE,kCAAkC;IAClC,wBAAwB;IACxB,uDAAuD;AAC3D","sourcesContent":[".wrapper {\r\n    /* max-height: var(--tg-viewport-height, 100vh); */\r\n    max-height: 100vh;\r\n    height: 100vh;\r\n    overflow: hidden;\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color: #0e0e0e;\r\n    width: 100vw;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.main {\r\n    position: relative;\r\n    background-color: var(--main-color);\r\n\r\n    height: 100vh;\r\n    /* max-height: var(--tg-viewport-height); */\r\n    max-height: 100vh;\r\n    /* width: 46.18vh; */\r\n    /* max-width: 56.25vh; */\r\n    max-width: 66.7vh;\r\n\r\n    width: 100vw;\r\n    /* background-image: url('http://localhost:3000/images/game-page2.png'); */\r\n    /* background-image: url('http://localhost:3000/images/game-boost.png'); */\r\n    /* background-image: url('http://localhost:3000/images/game-task.png'); */\r\n    /* background-image: url('http://localhost:3000/images/tp-d.png'); */\r\n    /* background-repeat: no-repeat; */\r\n    background-size: contain;\r\n    transition: background-color var(--transition-duration);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__rg3iR`,
	"main": `style_main__8sl0a`
};
export default ___CSS_LOADER_EXPORT___;
