import { useCallback, useEffect, useRef, useState } from 'react'
import { StyledGameUI } from './styled.page'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaBolt } from "react-icons/fa";

import cup from '../../assets/images/bronze-64.png';


import ProgressBar from '../../components/ProgressBarV3';
import GradientText from '../../components/GradientText';
import { useNavigate } from 'react-router-dom';
import { toNumberFormat } from '../../features/common';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Socket } from 'socket.io-client';
import { useDebouncedCallback } from 'use-debounce';
import { setAll } from '../../features/fullSlice';
import { useTheme } from '../../providers/Theme';
import CoinButton from '../../components/CoinButton';
import { FlyingNumbersRef } from '../../components/FlyingNumberCanvas';
import CustomCountUp from '../../components/CustomCountUp';
import ProgressBarWithRef from '../../components/ProgressBarV3.withRef';
import GradientTextWithRef from '../../components/GradientText.withRef';
import { UpdateRef } from '../../types/Interface';
import RestAPIClass from '../../services/rest.service';
import { errorHandler, leagueData } from '../../features/gameFunctions';
import { usePopup } from '../../providers/Popup';
import ProfitPopup from '../../components/ProfitPopup';


// function Index({ balance, per_tap, energy, storage, recharging_speed, leagueName, leagueImage }: { balance: number, per_tap: number, energy: number, storage: number, recharging_speed: number, leagueName: string, leagueImage: string }) {
const Index = ({ socket, league }: { socket: Socket, league: any }) => {

    const { theme } = useTheme();
    const { energy, inventory, per_tap, storage, speed, total_inventory, prev_inventory, loading, robotSecondsRemain, guru_mode, robotProfit, daily_counter } = useAppSelector(state => state.full);
    const { jwt } = useAppSelector(state => state.security);
    const leagues = useAppSelector(state => state.league.levels);
    console.log({ energy, inventory, per_tap, storage, speed, total_inventory });
    const [cupImage, setCupImage] = useState('/images/wood.png')
    const dispatch = useAppDispatch();
    const [current, setCurrent] = useState<number>(energy);
    const [currentTotal, setCurrentTotal] = useState<number>(inventory);
    const r_claim = useRef<number>(0);
    const r_current = useRef<number>(energy);
    const r_currentTotal = useRef<number>(inventory);
    const r_tapBuffer = useRef<number>(0);
    const r_guruBuffer = useRef<number>(0);
    // const [disabled, setDisabled] = useState(r_current.current < per_tap);
    const [disabled, setDisabled] = useState(current < per_tap);
    const r_disabled = useRef<boolean>(false)
    const r_guruMode = useRef<boolean>(guru_mode)
    // const [tapBuffer, setTapBuffer] = useState(0);
    // const [guruBuffer, setGuruBuffer] = useState(0);
    // const [isFull, setIsFull] = useState(energy == storage)
    const r_isFull = useRef<boolean>(energy == storage)
    const [style, setStyle] = useState({ transform: 'rotate3d(0, 0, 0, 0deg' });
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(['android', 'ios'].includes(Telegram.WebApp.platform));
    // const navigate = (url: string) => { }
    const classes = ['n', 'nw', 'w', 'sw', 's', 'se', 'e', 'ne'];

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    // const disabled = useRef<boolean>(current < per_tap);
    const [rect, setRect] = useState(canvasRef.current?.getBoundingClientRect())
    const numbersRef = useRef<{ x: number, y: number, value: number, startTime: number }[]>([]);
    const imgRef = useRef<HTMLImageElement>(null);
    const flyingNumbersRef = useRef<FlyingNumbersRef>(null);
    const countUpRef = useRef<UpdateRef<number>>(null);
    const progressRef = useRef<UpdateRef<number>>(null);
    const textRef = useRef<UpdateRef<number>>(null);
    const [canvasRect, setCanvasRect] = useState({ x: 0, y: 0 });
    const { show, setPopup, close } = usePopup();
    useEffect(() => {
        if (progressRef.current)
            progressRef.current.update(r_current.current);
        if (textRef.current)
            textRef.current.update(r_current.current);

        return () => {
            console.log('redux dispatch');

            dispatch(setAll({ prev_inventory: r_currentTotal.current, inventory: r_currentTotal.current, energy: r_current.current }));
        }
    }, [])


    useEffect(() => {
        setCanvasRect({ x: imgRef.current?.height ?? 0, y: imgRef.current?.width ?? 0 });
    }, [imgRef.current?.height, imgRef.current?.width])

    useEffect(() => {
        console.log({ robotProfit });

        if (robotProfit > 0) {
            setTimeout(() => {
                setPopup(<ProfitPopup profit={robotProfit} onCollect={(profit: number) => {
                    dispatch(setAll({ robotProfit: 0, inventory: inventory + robotProfit + r_claim.current }));
                    close();
                    r_claim.current = 0;
                }} />);
                show();
            }, 1000);
            // dispatch(setAll({ robotProfit: 0 }));
        }
    }, [robotProfit]);

    useEffect(() => {
        setTimeout(() => {
            new RestAPIClass(jwt).GetInitialData().then(response => {
                if (response.status === 'success') {
                    r_current.current = response.data.counter;
                    if (robotProfit > 0)
                        return;
                    //todo modify if inventory updated going back from other pages
                    r_currentTotal.current = response.data.user.inventory - response.data.robotProfit;
                    if (response.data.robotProfit > 0) {
                        dispatch(setAll({ total_inventory: response.data.user.total_inventory }));
                    }
                    else {
                        dispatch(setAll({ inventory: response.data.user.inventory, total_inventory: response.data.user.total_inventory }));
                    }
                } else {
                    errorHandler(response.error, "Could not sync game state")
                }
            }).catch(e => {
                errorHandler(e, 'init data error on game page')
            });
        }, 1000);
    }, []);

    useEffect(() => {
        try {
            let { league } = leagueData(leagues, total_inventory);
            if (league) {
                let image = league.image.replace('/images/', '/images/thumbnails/');
                setCupImage(image)
            }
        } catch (error) {
            console.log('could not set cup iamge', error);
        }

    }, [leagues])


    useEffect(() => {
        r_guruMode.current = guru_mode;
    }, [guru_mode])


    const Tap = useCallback((x: number, y: number) => {
        // console.log({ x, y, disabled, guru_mode: r_guruMode.current, setCurrent, setCurrentTotal });

        // if (disabled)
        if (r_disabled.current)
            return;
        flyingNumber(x, y);
        // if (!rect) {
        //     if (canvasRef.current) {
        //         setRect(canvasRef.current.getBoundingClientRect())
        //     }
        // } else {
        // toast(`x:${Math.round(x)}, y:${Math.round(y)}, l:${Math.round(rect.left)}, t:${Math.round(rect.top)}`)
        // numbersRef.current.push({ x: x - rect.left, y: y - rect.top, value: per_tap, startTime: Date.now() });
        numbersRef.current.push({ x, y, value: per_tap, startTime: Date.now() });
        const now = Date.now();
        numbersRef.current = numbersRef.current.filter(number => now - number.startTime < 1000);
        // console.log({ nrc: deepClone(numbersRef.current) });
        // if (flyingNumbersRef.current)
        //     flyingNumbersRef.current.updateValue(numbersRef.current);

        // while (numbersRef.current.length > 20)
        //     numbersRef.current.shift();
        // }
        let guru_mode = r_guruMode.current;
        let c = guru_mode ? per_tap * 5 : per_tap;
        // let c = guru_mode ? per_tap * 5 : per_tap;
        // if (countUpRef.current)
        //     countUpRef.current.updateValue(c)
        // if (guru_mode) {
        //     setCurrentTotal(t => t + c);
        //     setGuruBuffer(t => t + 1);
        //     guru_debounced()
        //     if (guruBuffer === 0)
        //         socket.emit('start_game', 'test');
        // } else {
        //     // setCurrent(t => t - c);
        //     // setCurrentTotal(t => t + c);
        //     // dispatch(updateInventory(c));
        //     // setTapBuffer(t => t + 1);
        //     // debounced();
        //     // if (tapBuffer === 0)
        //     //     socket.emit('start_game', 'test');
        // }

        // sectino use by ref
        if (guru_mode) {
            r_currentTotal.current += c;
            // setGuruBuffer(t => t + 1);
            guru_debounced()
            if (r_guruBuffer.current === 0)
                socket.emit('start_game', 'test');
            r_guruBuffer.current += 1;
        } else {
            if (c > r_current.current) {
                c = Math.round(r_current.current / per_tap) * per_tap;
                // setDisabled(true);
                r_disabled.current = true;
            }
            r_current.current -= c;
            r_currentTotal.current += c;

            if (r_isFull.current && r_current.current < storage) {
                r_isFull.current = false;
            }
            // if (progressRef.current)
            //     progressRef.current.update(r_current.current);
            // if (textRef.current)
            //     textRef.current.update(r_current.current);
            // setTapBuffer(t => t + 1);
            debounced();
            if (r_tapBuffer.current === 0)
                socket.emit('start_game', 'test');
            r_tapBuffer.current += 1;
        }
    }, [disabled, guru_mode]);

    useEffect(() => {
        console.log('tap callback regenerated', { guru_mode });
    }, [Tap])



    const handleResize = useDebouncedCallback(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            canvas.width = (imgRef.current?.width ?? 50) * 1.3;
            canvas.height = (imgRef.current?.height ?? 50) * 1.3;
            // console.log({ height, width });

            // canvas.width = height + 120;
            // canvas.height = height + 120;
        }
    }, 200);

    useEffect(() => {
        handleResize();
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        // canvas.width = window.innerWidth;
        // canvas.height = window.innerHeight;
        // canvas.width = height;
        // canvas.height = height;



        const render = () => {
            if (!ctx) return;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // ctx.beginPath();
            // ctx.arc(canvas.width / 2, canvas.height / 2, height / 2, 0, 2 * Math.PI);
            // ctx.strokeStyle = "blue";
            // ctx.stroke();
            const now = Date.now();
            numbersRef.current = numbersRef.current.filter(number => now - number.startTime < 1000);
            let size = 30;
            let charWidth = 25;
            numbersRef.current.forEach(number => {
                const timeElapsed = now - number.startTime;
                const progress = timeElapsed / 1000;
                const yOffset = progress * 200;
                // console.log(number);

                ctx.font = '30px Arial';
                ctx.fillStyle = `rgba(200, 200, 200, ${1 - progress})`;
                ctx.fillText(number.value + '+', number.x - (charWidth * number.value.toString().length / 2), (number.y + (size / 2)) - yOffset);
            });

            requestAnimationFrame(render);
        };

        render();

        // window.addEventListener('resize', handleResize);

        // return () => {
        //     window.removeEventListener('resize', handleResize);
        // };
    }, []);



    const flyingNumber = useCallback((x: number, y: number) => {
        if (r_disabled.current) return;
        let guru_mode = r_guruMode.current;
        const span = document.createElement('span');
        span.textContent = guru_mode ? (per_tap * 5) + '+' : per_tap + '+';
        span.classList.add('flying-number');
        span.style.left = x + 'px';
        span.style.top = y + 'px';
        document.body.appendChild(span);
        setTimeout(() => document.body.removeChild(span), 1000);
    }, [per_tap])


    //guru activation
    useEffect(() => {
        if (!guru_mode)
            return;
        let timeout = setTimeout(() => {
            dispatch(setAll({ guru_mode: false }));
            socket.emit("coin_snapping", r_guruBuffer.current)
        }, (20) * 1000);

        let interval = setInterval(() => {
            socket.emit("coin_snapping", r_guruBuffer.current);
            r_guruBuffer.current = 0;
        }, 1000)

        return () => {
            // socket.emit("coin_snapping", r_guruBuffer.current)
            clearTimeout(timeout);
            clearInterval(interval);
        }
    }, [guru_mode])

    // sync with redux 
    // useEffect(() => {
    //     setCurrent(energy);
    // }, [energy]);

    // sync with redux by ref
    useEffect(() => {
        r_current.current = energy;
    }, [energy]);

    // sync with redux 
    // useEffect(() => {
    //     setCurrentTotal(inventory);
    // }, [inventory])

    // sync with redux by ref
    useEffect(() => {
        r_currentTotal.current = inventory;
    }, [inventory])

    //main interval - component status sync
    useEffect(() => {
        const interval = setInterval(() => {
            let c = r_current.current;
            let f = r_isFull.current;
            if (!f && c >= storage) {
                c = storage;
                f = true;
                console.log({ f, energy: r_current.current });
            } else if (!f && c + speed > storage) {
                c = storage;
                f = true;
            } else if (!f) {
                c += speed;
            }
            r_isFull.current = f;
            if (f && robotSecondsRemain > 0)
                if (robotProfit <= 0)
                    r_currentTotal.current += speed;
                else
                    r_claim.current += speed;
            r_current.current = c;
            if (r_disabled.current && r_current.current > per_tap)
                // setDisabled(false);
                r_disabled.current = false;

        }, 1000);
        return () => clearInterval(interval);
    }, [robotProfit]);

    // init interval for rendering objects
    useEffect(() => {
        const interval = setInterval(() => {
            if (textRef.current)
                textRef.current.update(r_current.current);
            if (progressRef.current)
                progressRef.current.update(r_current.current);
            if (countUpRef.current)
                countUpRef.current.update(r_currentTotal.current);
            // console.log(r_currentTotal.current, r_current.current);

        }, 1000);
        return () => clearInterval(interval);
    }, []);


    const socketDataHandler = (args: any) => {
        console.log(args);

        dispatch(setAll(args))
    }

    // socket connection handler
    useEffect(() => {
        if (socket) {
            socket.emit('start_game', 'start');
            socket.on('update_inventory', socketDataHandler);

            return () => {
                socket.off('update_inventory', socketDataHandler);
            }
        }
    }, []);

    const debounced = useDebouncedCallback(() => {
        // let count = tapBuffer;
        let count = r_tapBuffer.current;
        console.log({ count });
        // setTapBuffer(0);
        r_tapBuffer.current = 0;
        // dispatch(setAll({ inventory: currentTotal, energy: current, total_inventory: total_inventory + tapBuffer }));
        socket.emit('coin_snapping', count);
    }, 1000)

    const guru_debounced = useDebouncedCallback(() => {
        // let count = guruBuffer;
        let count = r_guruBuffer.current;
        console.log({ count });
        // setGuruBuffer(0);
        r_guruBuffer.current = 0;
        // dispatch(setAll({ inventory: currentTotal, energy: current, total_inventory: total_inventory + guruBuffer }));
        socket.emit('coin_snapping', count);
    }, 500)

    if (loading)
        return <div>loading...</div>;

    return (
        <StyledGameUI className='main-section'>

            <section id="info">
                {/* <strong className='balance'>
                    {toNumberFormat(currentTotal)}
                </strong> */}
                <strong className='balance'>
                    <CustomCountUp ref={countUpRef} prev_inventory={prev_inventory} inventory={inventory} per_tap={per_tap} />
                </strong>
                {/* <button className="game-box" onTouchStart={() => dispatch(setAll({ guru_mode: !guru_mode }))}>guru {guru_mode ? 'On' : 'Off'}</button> */}
                <button className="game-box user-level clickable" onClick={() => navigate('leagues')}>
                    <BsChevronLeft />
                    <img height={30}
                        // src={league.image}  
                        src={cupImage}
                        onError={() => {
                            setCupImage(cup)
                        }}
                    />
                    {/* <span> </span> */}
                    <BsChevronRight />
                </button>
            </section>
            {/* <p className='gold-text'> {touches.length} - {touches.join(',')} - {Math.round(rect?.height ?? 0)},{Math.round(rect?.width ?? 0)} </p> */}
            <section id='coin'>
                {/* {guru_mode && <img src={firecircle} className={'fire'} />} */}
                {/* {guru_mode && <img src={theme === 'dark' ? '/images/fire_circle_dark.webp' : '/images/fire_circle_light.webp'} className={'fire'} />} */}
                {/* {!guru_mode && <video src={theme === 'dark' ? '/videos/fire_animation_dark.mp4' : '/videos/fire_animation_light.mp4'} poster={theme === 'dark' ? '/images/dark.png' : '/images/light.png'} className={'fire'} controls={false} autoPlay playsInline/>} */}
                {guru_mode && <img src={'/images/fire.png'} className={'fire'} />}
                <CoinButton disabled={r_disabled.current} Tap={Tap} ref={imgRef} />
                {/* <canvas
                    className='coin-overlay-canvas'
                    ref={canvasRef}
                // onClick={handleClick}
                // onTouchStart={handleTouch}
                /> */}
                {/* <FlyingNumbersCanvas
                    height={canvasRect.y}
                    width={canvasRect.x}
                    numbers={[...numbersRef.current]}
                    ref={flyingNumbersRef} /> */}
            </section>
            <section id='progress'>

                <div className="flex-h" style={{ paddingTop: '7px' }}>
                    <FaBolt color='#d07f00' />
                    {/* <GradientText grad_a='red' grad_b='blue' text='10/500' component='div' /> */}
                    {/* <GradientText grad_a='#EDCE96' grad_b='#A48148' text={`${toNumberFormat(r_current.current)} / ${toNumberFormat(storage)}`} component='div' className='progress-text' /> */}
                    <GradientTextWithRef storage={storage} grad_a='#EDCE96' grad_b='#A48148' text={`${toNumberFormat(current)} / ${toNumberFormat(storage)}`} component='div' className='progress-text' ref={textRef} />
                </div>
                <div style={{ padding: '0px 10px' }} >
                    {/* <ProgressBar percent={(r_current.current / storage) * 100} /> */}
                    <ProgressBarWithRef total={storage} ref={progressRef} value={r_current.current} />
                </div>
                <div className="flex-h">
                    <GradientText grad_a='#EDCE96' grad_b='#A48148' text={`Weekly`} component='div' className='progress-text' />
                </div>
                <div style={{ padding: '0px 10px' }} >
                    <ProgressBar percent={(daily_counter / 7) * 100} />
                </div>
            </section>
        </StyledGameUI>
    )
};

export default Index