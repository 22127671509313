import styled from 'styled-components';

const StyledGameUI = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 calc(var(--h-padding) - 7px);

    #info{
        height: 18%;
        max-height: 18%;
        overflow: hidden;
        padding: 0 var(--h-padding);
        justify-content: flex-end;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 8%;

        .title {
            background: -webkit-linear-gradient(0deg, #EDCE96, #A48148);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 38px;
            margin: 0;

        }
    }

    #player-list {
        height: 85%;
        max-height: 85%;
        
        .list{
            // gap: 2px;
            height: 100%;
            overflow: auto;
            padding: 10px 0px;
            
            .player{
                padding: 3px 15px;
                // margin: 4px 8px;
                justify-content: flex-start;
                align-items:center;
                gap: 8px;
                overflow: hidden;

                .rank{
                    font-size:1.2rem;
                    align-self: center;
                }
                
                .wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-self: center;
                    
                    .outer-circle {
                        margin:3px;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                        .inner-circle {
                            margin:3px;
                            width: 45px; /* 20px radius * 2 */
                            height: 45px;
                            border-radius: 50%;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            
                            img {
                                width: 35px;
                                height: 35px;
                                object-fit: cover;
                                border-radius: 50%;
                            }
                        }
                    }
                }
                
                .details{
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 0 10px;
                    gap:4px;
                    overflow: hidden;

                    .title{
                        text-align: left;
                        color: var(--text-color);
                        font-size: 0.8rem;
                        // font-weight: 600;
                        text-transform: capitalize;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        // width: 100px;
                        display: block;
                        overflow: hidden;
                        width: 100%;
                    }
                    .reward{
                        text-align: left;
                        color: var(--text-color-1);
                        font-size: 0.8rem;
                        
                    }
                    .stars{
                        gap:4px;
                        
                        img{
                            height:15px;
                        }
                    }
                }
            }
        }
    }
`

export { StyledGameUI }