import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react'
import GradientText from './GradientText'
import { UpdateRef, UpdateRefNumber } from '../types/Interface'

type GradientTextInput = {
    grad_a: string,
    grad_b: string,
    text: string,
    component?: string,
    className?: string,
    storage: number
}



const GradientTextWithRef = forwardRef((props: GradientTextInput, ref: Ref<UpdateRef<number>>) => {

    const [text, setText] = useState(props.text);
    useImperativeHandle(ref, () => ({
        update(newValue) {
            // console.log('gradient Text');
            setText(`${newValue} / ${props.storage}`);
            
        }
    }));
    return <GradientText {...props} text={text} />
})

export default GradientTextWithRef