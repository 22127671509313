import Layout from './Layout';
import ProgressBar from '../components/ProgressBarV3';
import styled from 'styled-components';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import TrophyLevel from '../types/TrophyLevel';
import { images } from '../data';
import { clamp, deepClone, loopClamp, toNumberFormat } from '../features/common';
import { useTheme } from '../providers/Theme';
import { leagueData } from '../features/gameFunctions';
const StyledDiv = styled.div`
justify-content: space-around;
padding: 0 var(--h-padding);
height: 100%;
.head{
    height: 30%;
    align-content: flex-end;
    .header{
        font-size: var(--header-font-size);
        font-weight: bold;
        text-transform: capitalize;
    }
    .divider{
        height: 10px;
        margin: 15px 0;
    }
    p {
        font-size: var(--font-size-2);
        color: var(--text-color-1);
    }
}
.trophy{
    height: 35%;
    justify-content: space-between;
    align-items: center;
    button{
        padding: 5px;

        svg{
            pointer-events:none;
        }
    }
    img{
        height: 80%;
    }
}

.progress, .level{
    height: 15%;
}

.level{
    color: var(--text-color);
    font-size: var(--font-size-3);
}

`



function Leagues() {
    const handleTrophyIndex = (op: string) => {
        if (op === '+')
            setActiveIndex(i => loopClamp(0, levels.length - 1, i, i + 1));
        else if (op === '-')
            setActiveIndex(i => loopClamp(0, levels.length - 1, i, i - 1));
    }
    const navigate = useNavigate();
    const { theme } = useTheme();
    const { league, full: { total_inventory } } = useAppSelector(state => state);
    let levels = deepClone(league.levels);
    // levels = levels.sort((a, b) => b.count - a.count);
    const { leagueIndex, league: activeLeague } = leagueData(levels, total_inventory);
    const [activeIndex, setActiveIndex] = useState(leagueIndex);
    useEffect(() => {
        setErrored(false);
    }, [activeIndex])

    useEffect(() => {
        const handler = () => {
            navigate('/');
            Telegram.WebApp.BackButton.hide();
            Telegram.WebApp.BackButton.offClick(handler);
        }
        Telegram.WebApp.BackButton.onClick(handler);
        Telegram.WebApp.BackButton.show();
    }, [])

    const [errored, setErrored] = useState(false);

    const onError = () => {
        setErrored(true);
    }

    return (
        <Layout>
            <StyledDiv className='flex flex-v'>
                <div className="head flex-flex-v">
                    <div className='gold-text header'>{levels[activeIndex].title}</div>
                    <div className="game-box divider"></div>
                    <p>Your number of shared determines the league you enter.</p>
                </div>
                <div className="trophy flex flex-h">
                    <button className={`clickable game-box${activeIndex === 0 ? ' invisible' : ''}`} onClick={() => handleTrophyIndex('-')} disabled={activeIndex == 0}>
                        <BsChevronLeft size={25} style={{ color: 'var(--menu-btn-color)' }} />
                    </button>
                    {/* <img src={trophies[activeIndex % trophies.length]} /> */}
                    {/* <img src={!errored ? `${levels[activeIndex].image}` : theme === 'dark' ? '/images/cup_placeholder_dark.png' : '/images/cup_placeholder_light.png'} onError={onError} /> */}
                    <img src={!errored ? `${levels[activeIndex].image}` : '/images/cup_placeholder.png'} onError={onError} />
                    <button className={`clickable game-box${activeIndex === levels.length - 1 ? ' invisible' : ''}`} onClick={() => handleTrophyIndex('+')}>
                        <BsChevronRight size={25} style={{ color: 'var(--menu-btn-color)' }} />
                    </button>
                </div>
                {leagueIndex == activeIndex ?
                    <div className="progress">
                        <p className="gold-text">{toNumberFormat(total_inventory)} / {toNumberFormat(levels[clamp(0, levels.length - 1, leagueIndex)].max)}</p>
                        <ProgressBar percent={(total_inventory / levels[clamp(0, levels.length - 1, leagueIndex)].max) * 100} />
                    </div> :
                    <div className="level">
                        <p>From {toNumberFormat(levels[activeIndex].count)}</p>
                    </div>
                }
            </StyledDiv>
        </Layout>
    )
}

export default Leagues