import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
    color: white;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:0 calc(var(--h-padding) - 5px);
    gap: 5px;
    overflow:hidden;
    --edge: 42px;
    button{
        width: var(--edge);
        height: var(--edge);
        // height: 50%;
        padding:5px;
        margin: 4px;
        // color:#777;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;
        svg{
            // stroke: #777;
            fill: #777;
        }
    }
    button.active{
        flex:2;
        width: calc(2 * var(--edge));
        color: #c9a870;
        font-wight:bold;
        
        svg{
            fill: #c9a870;
        }
    }
`

export { StyledFooter };