import { Footer } from '../sections/Footer'
import { HomePage } from '../sections/HomePage'
import { GamePage } from '../sections/GamePage'
import { TaskPage } from '../sections/TaskPage';
import { StatsPage } from '../sections/StatsPage';
import { ReferralsPage } from '../sections/ReferralsPage';
import { BooleanProvider } from '../hooks/DrawerHandler';
import Layout from './Layout';
import { useAppSelector } from '../hooks';
import { Socket } from 'socket.io-client';
import { TopUsers } from '../sections/TopUsers';
// import { ThemeProvider } from 'styled-components';
const theme = {
    dark: 'dark'
}

function Game({ socket }: { socket: Socket }) {
    const { activePage: page } = useAppSelector(state => state.page);
    const { full: { energy, per_tap, inventory, total_inventory, speed: recharging_speed, storage } } = useAppSelector((state) => state);
    const { levels } = useAppSelector((state) => state.league);

    const index = levels.findIndex(i => i.count <= total_inventory);
    const level = levels[index];
    const league = level || levels[0];
    

    return <Layout>
        <section className='head-section'></section>
        {
            [
                {
                    page: 'refs',
                    component: <ReferralsPage />
                },
                {
                    page: 'tasks',
                    component: <TaskPage />
                },
                {
                    page: 'home',
                    // component: <GamePage energy={energy} per_tap={per_tap} balance={inventory} storage={storage} recharging_speed={recharging_speed} leagueImage={image} leagueName={leagueName} />
                    component: <GamePage league={league} socket={socket} />
                },
                {
                    page: 'boost',
                    component: <BooleanProvider> <HomePage /> </BooleanProvider>
                },
                {
                    page: 'apps',
                    component: <StatsPage />
                },
                {
                    page: 'top_users',
                    component: <TopUsers />
                },
            ].find(i => i.page === page)?.component ?? <div>Not Found</div>

        }
        <Footer />
    </Layout >


}

export default Game